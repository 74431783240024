import React, {Component} from 'react';
import './conceptualize-banner@1x.jpg';
import './conceptualize-banner@1x.webp';
import Story_JPG_1x from './conceptualize-story@1x.jpg';
import Wireframe_JPG_1x from './conceptualize-wireframes@1x.jpg';
import Story_WEBP_1x from './conceptualize-story@1x.webp';
import Wireframe_WEBP_1x from './conceptualize-wireframes@1x.webp';

import './conceptualize.scss';
import {PageFooter} from '../../components/Footer';
import {PageHeader} from "../../components/Header";

import PageHead, {BodyType1} from '../../components/Pages';

const PageHeadTitle = "Conceptualize";

const PageHeadBody1 = "<p>\n" +
    "After establishing the necessary <a href='/research'>research</a> to define <a href='https://www.interaction-design.org/literature/article/personas-why-and-how-you-should-use-them' target='_blank' rel='noopener noreferrer'>personas</a>; their objectives &amp; pain-points, \n" +
    "I would work my way to tie my findings to business goals and challenges.\n" +
    "</p>";

const PageHeadBody2 = "<p>\n" +
    "This is also the time where I would channel my efforts on ideation. \n" +
    "Putting ideas through a diverging and converging process, so as to achieve the optimal approach in addressing earlier findings. \n" +
    "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Story";
const BodyText1 = "<p>" +
    "To help communicate my design, I craft journeys accounting for the events from the user’s perspective;&nbsp;" +
    "including cases and scenarios to depict the evolution of an experience. \n" +
    "</p>";
const BodyTools1 = [
    {
        "tool": "Wireflow",
        "link": "https://www.nngroup.com/articles/wireflows/"
    },
    {
        "tool": "Storyboard",
        "link": "https://www.smashingmagazine.com/2017/10/storyboarding-ux-design/"
    },
    {
        "tool": "Journey map",
        "link": "https://boagworld.com/audio/customer-journey-mapping/"
    },
    {
        "tool": "Persona",
        "link": "https://www.usability.gov/how-to-and-tools/methods/personas.html"
    },
    {
        "tool": "Scenario",
        "link": "https://pidoco.com/en/help/ux/user-scenario"
    }
];

const BodyHeading2 = "Wireframe";
const BodyText2 = "<p>" +
    "I love building wireframes - the \"design skeletons\" to guide the structure &amp; layout of content in user interface screens." +
    "&nbsp;" +
    "This is my space of expressing critical thinking of the main visual of the user interface,&nbsp;" +
    "beyond the subjectivity of aesthetics and cultural differences." +
    "</p><p> \n" +
    "For any UI related work, this is my necessary medium; to convey the required information to stakeholders and establishing consensus, before starting on solutioning." +
    "</p>"
const BodyTools2 = [
    {
        "tool": "Balsamiq mockups",
        "link": "https://balsamiq.com/wireframes/desktop/"
    },
    {
        "tool": "Omnigraffle",
        "link": "https://www.omnigroup.com/omnigraffle/"
    },
    {
        "tool": "Axure RP",
        "link": "https://www.axure.com/"
    },
    {
        "tool": "Sketch",
        "link": "https://www.sketch.com/"
    },
    {
        "tool": "Figma",
        "link": "https://www.figma.com/"
    }
];

class Conceptualize extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-conceptualize" className="skills-pages">
                <PageHeader/>
                <PageHead title={PageHeadTitle} body={PageHeadBody}/>
                <BodyType1 title={PageHeadTitle}
                           subtitle1={BodyHeading1} body1={BodyText1} tools1={BodyTools1}
                           image1JPG={Story_JPG_1x} image1WEBP={Story_WEBP_1x}
                           subtitle2={BodyHeading2} body2={BodyText2} tools2={BodyTools2}
                           image2JPG={Wireframe_JPG_1x} image2WEBP={Wireframe_WEBP_1x}/>
                <PageFooter/>
            </div>
        )
    }
}

export default Conceptualize