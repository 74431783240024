import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import Loader from "../loader/loader";
import payLoad from "./horizontal_bar.json";

const chartLoadTime = 1000;

const chartColors = {
  white_100: "rgba(255, 255, 255, 1)",
  white_75: "rgba(255, 255, 255, .75)",
  white_50: "rgba(255, 255, 255, .5)",
  white_25: "rgba(255, 255, 255, .25)",
  white_10: "rgba(255, 255, 255, .10)",
  white_5: "rgba(255, 255, 255, .05)",
};

class ChartHortBar extends Component {
  state = {
    loading: true,
    Dimensions: [],
    Values: [],
  };

  componentDidMount() {
    let dim_Array = [];
    let dim_Value = [];

    for (let [key, value] of Object.entries(payLoad)) {
      dim_Array.push(key);
      dim_Value.push(value);
    }

    this.setState(
      {
        Dimensions: dim_Array,
        Values: dim_Value,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              loading: false,
            }),
          chartLoadTime + 4000
        ),
      console.info("Horizontal bar status: loaded from local")
    );
  }

  render() {
    const shades = (data) => {
      let arrayData = [];
      const maxOpacity = 0.75;
      const deltaOpacity = (maxOpacity - 0) / data.length;

      for (let index = data.length; index > 0; index--) {
        arrayData.push("rgba(255, 255, 255," + deltaOpacity * index + ")");
      }
      return arrayData;
    };

    return (
      <div>
        <Loader loading={this.state.loading} />
        <div
          className="chart-container"
          style={{ visibility: this.state.loading ? "hidden" : "visible" }}
        >
          <HorizontalBar
            data={{
              labels: this.state.Dimensions,
              datasets: [
                {
                  label: this.props.chartLabel,
                  backgroundColor: chartColors.white_25,
                  borderColor: chartColors.white_50,
                  borderWidth: 1,
                  hoverBackgroundColor: chartColors.white_50,
                  hoverBorderColor: chartColors.white_100,
                  data: this.state.Values,
                },
              ],
            }}
            width={this.props.chartWidth}
            height={this.props.chartHeight}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false,
                labels: {
                  boxWidth: 10,
                  padding: 20,
                },
                position: "bottom",
              },
              tooltips: {
                enabled: true,
              },
              scales: {
                xAxes: [
                  {
                    gridLines: {
                      color: chartColors.white_25,
                      borderDash: [1, 5],
                      zeroLineColor: chartColors.white_50,
                    },
                    ticks: {
                      beginAtZero: true,
                      fontColor: chartColors.white_50,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default ChartHortBar;
