import React, { Component } from "react";
import ChartDoughnut from "../../widgets/charts/doughnut";
import ChartHortBar from "../../widgets/charts/horizontal_bar";
import ChartRadar from "../../widgets/charts/radar";

import "./process.scss";
import "./banner@1x.jpg";

class Process extends Component {
  componentDidMount() {
    console.log("Loaded process section");
  }

  render() {
    return (
      <div>
        <div
          className="uk-inline uk-margin-xlarge-bottom process-photostrip"
          data-aos="zoom-out-up"
        >
          <div
            className={
              "uk-background-norepeat uk-background-blend-luminosity process-photostrip-background"
            }
          ></div>
          <div className={"uk-overlay-primary uk-position-cover"}></div>
          <div className={"uk-overlay uk-position-center-right uk-light"}>
            <h2 className={"uk-text-right@s uk-text-uppercase"}>
              Engineer
              <br />
              turned
              <br />
              UX designer
            </h2>
          </div>
        </div>

        <section
          id={"process"}
          className={
            "uk-position-relative uk-flex uk-flex-center uk-padding-remove-top"
          }
        >
          <div
            className={
              "uk-width-1-1@s uk-width-3-4@xl uk-child-width-1-3@m uk-grid-large"
            }
            data-uk-parallax="opacity: 1, 0.2; viewport: 0.95;  media: @m"
            data-uk-grid
          >
            {/*1st Container*/}
            <div
              className={"uk-text-center section-title"}
              id={"chart1"}
              data-aos="zoom-in"
            >
              <div className="uk-card uk-card-body section-title">
                <div className={"uk-margin-large-bottom uk-text-center"}>
                  <div className={"uk-text-uppercase profile-title"}>
                    Exposure
                  </div>
                  <p className={"uk-text-small"}>
                    Hands-on frameworks, libraries and languages
                  </p>
                </div>
                <div>
                  <ChartHortBar
                    chartLabel={"Competency level"}
                    chartWidth={300}
                    chartHeight={300}
                    chartSrcId={"1"}
                  />
                </div>
              </div>
            </div>

            {/*2nd Container*/}
            <div
              className={"uk-text-center section-title"}
              id={"chart2"}
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className={"uk-card uk-card-body section-title"}>
                <div className={"uk-margin-large-bottom uk-text-center"}>
                  <div className={"uk-text-uppercase profile-title"}>
                    Platforms
                  </div>
                  <p className={"uk-text-small"}>
                    Applications and technologies worked to date
                  </p>
                </div>
                <div>
                  <ChartDoughnut
                    chartLabel={"Projects"}
                    chartWidth={300}
                    chartHeight={300}
                    chartSrcId={"2"}
                  />
                </div>
              </div>
            </div>

            {/*3rd Container*/}
            <div
              className={"uk-text-center section-title"}
              id={"chart3"}
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className={"uk-card uk-card-body section-title"}>
                <div className={"uk-margin-large-bottom uk-text-center"}>
                  <div className={"uk-text-uppercase profile-title"}>
                    Effort
                  </div>
                  <p className={"uk-text-small"}>
                    Level of effort spent across project types
                  </p>
                </div>
                <div>
                  <ChartRadar
                    chartLabel={"Projects"}
                    chartWidth={300}
                    chartHeight={300}
                    chartSrcId={"3"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id={"flow"}
          className={
            "uk-position-relative uk-flex uk-flex-center uk-padding-remove-top"
          }
          hidden
        >
          <div
            className={
              "uk-text-center uk-width-1-1@s uk-width-3-4@xl uk-margin-remove"
            }
            data-aos="zoom-in"
          >
            <div className={"uk-card uk-card-body section-title"}>
              <div className={"uk-margin-large-bottom uk-text-center"}>
                <div className={"uk-text-uppercase profile-title"}>Process</div>
                <p className={"uk-text-small"}>My design process flow</p>
              </div>
              <div>To be inserted.</div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Process;
