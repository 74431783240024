import React, { Component } from "react";
import { defaults } from "react-chartjs-2";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Header from "./components/Header";
import Landing from "./components/Landing";
import Profile from "./components/Profile";
import Process from "./components/Process";
import Skills from "./components/Skills";
import Works from "./components/Works";
import Resume from "./components/Resume";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
// import Loaders from './widgets/loader/loader';
import Spinners from "./widgets/loader/spinner";

//Import Pages
import Communicate from "./pages/communicate/communicate";
import Conceptualize from "./pages/conceptualize/conceptualize";
import Develop from "./pages/develop/develop";
import Research from "./pages/research/research";
import Solutioning from "./pages/solutioning/solutioning";
import Workshops from "./pages/workshops/workshops";

// Import UIKit
import UIkit from "uikit";
import "uikit/dist/css/uikit.min.css";
import Icons from "uikit/dist/js/uikit-icons";
import "./App.scss";

// Import Analytics and Feature flags
import { configCatClient } from "./analytics/configcat";
import analyticsSetUser, {
  analyticsGateway,
  analyticsRemoveUser,
} from "./analytics/gateway";
import { history } from "./analytics/sentry";

// Import AOS
import AOS from "aos";
import "aos/dist/aos.css";

// Initialize UIKit
UIkit.use(Icons);

// Constants to use across app //
defaults.global.defaultColor = "rgba(255, 255, 255, 1)";
defaults.global.defaultFontColor = "rgba(255, 255, 255, 1)";
defaults.global.defaultFontFamily =
  "'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

const firstName = "Alan";
const lastName = "Ho";
const webUpdated = "2020-12-28";
const pdfUpdated = "2020-12-27";
const loaderDelay = 1000;

// Initilize Analytics Gateway
analyticsGateway();

// Access feature flags and settings
// configCatClient.getValueAsync("delighted", false).then((value) => {
//   window.ff_delighted = value;
// });

// Constants to use across app //

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      environment: "Production",
      user: {
        name: "",
        email: "",
        picture: "",
        sub: "",
      },
    };
  }

  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          loading: false,
        }),
      loaderDelay
    );

    setTimeout(
      () =>
        this.setState({
          user: {
            name: localStorage.getItem("name"),
            email: localStorage.getItem("email"),
            picture: localStorage.getItem("picture"),
            sub: localStorage.getItem("sub"),
          },
        }),
      loaderDelay + 250
    );

    setTimeout(() => {
      if (this.state.user.sub != null) {
        analyticsSetUser(
          this.state.user.name,
          this.state.user.email,
          this.state.user.sub
        );
      } else {
        analyticsRemoveUser();
        console.log("User is not logged in");
      }
    }, loaderDelay + 500);

    setTimeout(
      () =>
        AOS.init({
          disable: "mobile",
          startEvent: "DOMContentLoaded",
        }),
      loaderDelay
    );

    configCatClient.getValueAsync("environment", false).then((value) => {
      this.setState({
        environment: value,
      });
    });
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <Spinners loading={this.state.loading} ratio={2} />;
    }

    const isDevelopment = this.state.environment !== "Production";

    let envBanner;
    if (isDevelopment) {
      envBanner = (
        <Banner environment={this.state.environment + ` environment`} />
      );
    }

    const NotFoundRedirect = () => <Redirect to="/" />;

    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <div className={"uk-offcanvas-content"}>
              {envBanner}
              <Header />
              <Landing />
              <Profile firstName={firstName} lastName={lastName} />
              <Process />
              <Skills />
              <Works />
              <Resume webUpdated={webUpdated} pdfUpdated={pdfUpdated} />
              <Contact />
              <Footer firstName={firstName} lastName={lastName} />
            </div>
          </Route>
          <Route path="/communicate">
            <Communicate />
          </Route>
          <Route path="/conceptualize">
            <Conceptualize />
          </Route>
          <Route path="/develop">
            <Develop />
          </Route>
          <Route path="/research">
            <Research />
          </Route>
          <Route path="/solutioning">
            <Solutioning />
          </Route>
          <Route path="/workshops">
            <Workshops />
          </Route>
          <Route component={NotFoundRedirect} />
        </Switch>
      </Router>
    );
  }
}

export default App;
