import React, {Component} from 'react';
import './body.scss';

const ToolList = (props) => {
    if (props.list.length !== 0) {
        return <div>
            <hr className="uk-divider-small"/>
            <h6 className="uk-margin-remove-bottom uk-text-small uk-margin-medium-top uk-text-uppercase">Tools and
                methods</h6>
            <ul className="skills-tools uk-list uk-margin-small-top">
                {props.list.map((data, index) => {
                    return (
                        <li key={index} className="uk-border-pill uk-text-capitalize">
                            <a href={data.link} className="pill-link" target="_blank" rel="noopener noreferrer">
                                {data.tool}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </div>;
    }
    return null
};


class BodyType1 extends Component {

    render() {
        return (
            <div>
                <section className="uk-container uk-margin-large-top uk-margin-large-bottom">
                    <div className="uk-text-center uk-margin-medium-bottom">
                        <h6 className="uk-text-uppercase uk-text-small uk-margin-remove">
                            {this.props.title}
                        </h6>
                        <h3 className="uk-text-uppercase uk-text-bold uk-light uk-margin-medium-top uk-margin-remove">
                            {this.props.subtitle1}
                        </h3>
                    </div>
                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l uk-text-small"
                             dangerouslySetInnerHTML={{__html: this.props.body1}}>
                        </div>
                    </div>

                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l uk-text-small">
                            <ToolList list={this.props.tools1}/>
                        </div>
                    </div>

                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l">
                            <picture className="skills-pictures">
                                <source srcSet={this.props.image1WEBP} type="image/webp"/>
                                <source srcSet={this.props.image1JPG} type="image/jpeg"/>
                                <img src={this.props.image1JPG} alt={this.props.image1JPG}/>
                            </picture>
                        </div>
                    </div>
                </section>

                <section className="uk-container uk-margin-large-top uk-margin-large-bottom">
                    <div className="uk-text-center uk-margin-medium-bottom">
                        <h6 className="uk-text-uppercase uk-text-small uk-margin-remove">
                            {this.props.title}
                        </h6>
                        <h3 className="uk-text-uppercase uk-text-bold uk-light uk-margin-medium-top uk-margin-remove">
                            {this.props.subtitle2}
                        </h3>
                    </div>
                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l uk-text-small"
                             dangerouslySetInnerHTML={{__html: this.props.body2}}>
                        </div>
                    </div>

                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l uk-text-small">
                            <ToolList list={this.props.tools2}/>
                        </div>
                    </div>

                    <div className="uk-flex-center" data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l">
                            <picture className="skills-pictures">
                                <source srcSet={this.props.image2WEBP} type="image/webp"/>
                                <source srcSet={this.props.image2JPG} type="image/jpeg"/>
                                <img src={this.props.image2JPG} alt={this.props.image2JPG}/>
                            </picture>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

class BodyType2 extends Component {
    render() {
        return (
            <div className="uk-container uk-margin-xlarge-top">
                <section className="uk-flex-center uk-flex uk-flex-middle" data-uk-grid>
                    <div className="uk-width-1-1@s uk-width-1-2@m">
                        <h6 className="uk-text-uppercase uk-text-small uk-margin-remove">
                            {this.props.title}
                        </h6>
                        <h3 className="uk-text-uppercase uk-text-bold uk-light uk-margin-remove">
                            {this.props.subtitle1}
                        </h3>
                        <div className="uk-text-small uk-margin-medium-bottom"
                             dangerouslySetInnerHTML={{__html: this.props.body1}}>
                        </div>
                        <div className="uk-text-small">
                            <ToolList list={this.props.tools1}/>
                        </div>
                    </div>
                    <div className="uk-width-1-1@s uk-width-1-2@m">
                        <picture className="skills-pictures">
                            <source srcSet={this.props.image1WEBP} type="image/webp"/>
                            <source srcSet={this.props.image1JPG} type="image/jpeg"/>
                            <img src={this.props.image1JPG} alt={this.props.image1JPG}/>
                        </picture>
                    </div>
                </section>

                <section className="uk-flex-center uk-flex uk-flex-middle" data-uk-grid>
                    <div className="uk-width-1-1@s uk-width-1-2@m">
                        <h6 className="uk-text-uppercase uk-text-small uk-margin-remove">
                            {this.props.title}
                        </h6>
                        <h3 className="uk-text-uppercase uk-text-bold uk-light uk-margin-remove">
                            {this.props.subtitle2}
                        </h3>
                        <div className="uk-text-small uk-margin-medium-bottom"
                             dangerouslySetInnerHTML={{__html: this.props.body2}}>
                        </div>
                        <div className="uk-text-small">
                            <ToolList list={this.props.tools2}/>
                        </div>
                    </div>
                    <div className="uk-width-1-1@s uk-width-1-2@m uk-flex-first@m">
                        <picture className="skills-pictures">
                            <source srcSet={this.props.image2WEBP} type="image/webp"/>
                            <source srcSet={this.props.image2JPG} type="image/jpeg"/>
                            <img src={this.props.image2JPG} alt={this.props.image2JPG}/>
                        </picture>
                    </div>
                </section>
            </div>
        )
    }
}

export default BodyType1
export {BodyType2}