import React from 'react';
import TypeText from '../../widgets/typed/typed';
import './landing.scss';

const Landing = () => {
    return (
        <section id="landing"
                 className={"uk-position-relative uk-flex uk-flex-center uk-flex-middle uk-padding-remove-top uk-text-center data-uk-height-viewport"}>
            <div className="uk-width-1-1@s uk-width-3-4@m" data-uk-parallax="opacity: 1,0; viewport: 0.5;">
                <div className="uk-cover-container">
                    <h1 id="landing-intro"
                        className="uk-text-uppercase uk-text-bold uk-margin-remove-bottom uk-padding-remove-bottom">
                        Alan
                    </h1>

                    <p className="uk-margin-remove" id="my-title">
                        <TypeText
                            strings={[
                                "experience designer",
                                "product designer",
                                "user researcher",
                                "behavioral analyst",
                                "accessibility expert",
                                "UI designer",
                                "storyteller",
                                "prototyper",
                                "interaction designer",
                                "front-end engineer",
                            ]}
                        />
                    </p>
                    <div id="my-cv" className="uk-text-uppercase uk-margin-medium-top uk-padding-small uk-text-muted">
                        <span>
                            Like to know what I do?
                        </span>
                    </div>
                </div>
                <div className="uk-position-bottom-center">
                    <p className="uk-text-small uk-margin-medium-bottom">
                        <a href="#profile" data-uk-scroll>Just scroll down</a>.
                    </p>
                </div>
            </div>
        </section>
    )
};

export default Landing;