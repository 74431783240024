import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./skills.scss";

// Imagery to support component rendering //
import imgSkills from "./skills.svg";
import "./skills_research@1x.png";
import "./skills_communicate@1x.jpg";
import "./skills_communicate@2x.jpg";
import "./skills_conceptualize@1x.jpg";
import "./skills_conceptualize@2x.jpg";
import "./skills_solutioning@1x.png";
import "./skills_develop@1x.png";
import "./skills_workshop@1x.jpg";
import "./skills_workshop@2x.jpg";

// Imagery to support component rendering //

class Skills extends Component {
  state = {
    Path: "#",
  };

  componentDidMount() {
    if (process.env.NODE_ENV !== "production") {
      this.setState({
        Path: "/",
      });
    } else {
      this.setState({
        Path: "#",
      });
    }
  }

  render() {
    return (
      <section id="skills" className={"uk-text-center"}>
        <img src={imgSkills} alt={"Skills Logo"} />
        <h1 className="uk-text-center uk-heading-primary uk-text-uppercase uk-margin-remove-bottom uk-margin-small-top">
          Skills
        </h1>
        <h5 className="uk-text-center uk-margin-small-top">
          The types of expertise I offer
        </h5>

        <div
          className="container uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-collapse uk-text-center uk-margin-large-top uk-margin-xlarge-bottom"
          data-uk-grid
          data-uk-scrollspy="cls: uk-animation-fade; target: > .uk-tile; delay: 300; repeat: false"
        >
          <div
            id="research"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Research</h4>
              <p>Conduct quantitative and qualititative studies</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}research`}
            ></Link>
          </div>

          <div
            id="communicate"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Communicate</h4>
              <p>Elicit, distil and translate user requirements</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}communicate`}
            ></Link>
          </div>

          <div
            id="concepts"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Conceptualize</h4>
              <p>Ideate and develop strategic visions</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}conceptualize`}
            ></Link>
          </div>

          <div
            id="solutioning"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Solutioning</h4>
              <p>Map user journeys and information architecture</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}solutioning`}
            ></Link>
          </div>

          <div
            id="develop"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Develop</h4>
              <p>Craft user interfaces and interaction behaviors</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}develop`}
            ></Link>
          </div>

          <div
            id="workshops"
            className={
              "skills-image uk-inline uk-tile uk-tile-secondary uk-cover-container"
            }
          >
            <div className="uk-overlay-primary uk-position-cover"></div>
            <div className="uk-overlay uk-position-top uk-margin-large-top">
              <h4>Workshops</h4>
              <p>Participate and conduct design thinking sessions</p>
            </div>
            <Link
              className="uk-position-cover cursor-unset"
              to={`${this.state.Path}workshops`}
            ></Link>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
