import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import "./footer.scss";

function returnCurrentYear() {
  let now = new Date();
  let isoData = now.toISOString();
  let year = moment(isoData).format("YYYY");
  return year;
}

const Footer = (props) => {
  return (
    <footer>
      <div
        className="uk-child-width-1-1 uk-grid-collapse uk-text-center"
        data-uk-grid
      >
        <p className="uk-text-center">
          <span>
            Designed and developed in&nbsp;
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.python.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Python
            </a>
            <br />
            by &copy; {props.firstName}
            &nbsp;{props.lastName}, &nbsp;{returnCurrentYear()}
          </span>
        </p>
      </div>
    </footer>
  );
};

const PageFooter = () => {
  return (
    <footer className="page-footer uk-text-center uk-margin-xlarge-top uk-padding-large uk-dark uk-flex uk-flex-center uk-flex-middle">
      <Link to="/">
        <div>
          <h3 className="uk-text-uppercase uk-text-bold">Back</h3>
          <h1 className="uk-text-uppercase uk-text-bold uk-heading-xlarge uk-light uk-margin-remove">
            Home
          </h1>
        </div>
      </Link>
    </footer>
  );
};

export default Footer;
export { PageFooter };
