import * as configcat from "configcat-js";

// Check localhost
import { isLocalhost } from "./isLocal";

// Initialize ConfigCat
// let logger = configcat.createConsoleLogger(3); // Setting log level to 3 (Info)

let CONFIG_CAT_KEY = process.env.REACT_APP_CONFIG_CAT;
if (isLocalhost) {
  CONFIG_CAT_KEY = "qRvZCL-AkEKJGO5Q_4x6IQ/19nS90x-HkS4dIvz6Tr4WA";
}

export const configCatClient = configcat.createClientWithAutoPoll(
  CONFIG_CAT_KEY,
  {
    pollIntervalSeconds: 60,
    // logger: logger,
  }
);
