import React, { Component } from "react";
import SlideShow from "../../widgets/slideshow/slides";

import "./works.scss";

// Imagery to support component rendering //
import imgWorks from "./projects.svg";

// Profile images
import imgWorksProfile_PNG_1x from "./works_profile@1x.png";
import imgWorksProfile_PNG_2x from "./works_profile@2x.png";
import imgWorksProfile_PNG_3x from "./works_profile@3x.png";
import imgWorksProfile_WebP_1x from "./works_profile@1x.webp";
import imgWorksProfile_WebP_2x from "./works_profile@2x.webp";
import imgWorksProfile_WebP_3x from "./works_profile@3x.webp";

// Profile modal hero
import imgWorksProfileHero_PNG_1x from "./works_profile_hero@1x.png";
import imgWorksProfileHero_PNG_2x from "./works_profile_hero@2x.png";
import imgWorksProfileHero_PNG_3x from "./works_profile_hero@3x.png";
import imgWorksProfileHero_WEBP_1x from "./works_profile_hero@1x.webp";
import imgWorksProfileHero_WEBP_2x from "./works_profile_hero@2x.webp";
import imgWorksProfileHero_WEBP_3x from "./works_profile_hero@3x.webp";

// Identity modal empathize
import imgWorksProfileEmpathize_PNG_1x from "./works_profile_empathize@1x.png";
import imgWorksProfileEmpathize_PNG_2x from "./works_profile_empathize@2x.png";
import imgWorksProfileEmpathize_PNG_3x from "./works_profile_empathize@3x.png";
import imgWorksProfileEmpathize_WEBP_1x from "./works_profile_empathize@1x.webp";
import imgWorksProfileEmpathize_WEBP_2x from "./works_profile_empathize@2x.webp";
import imgWorksProfileEmpathize_WEBP_3x from "./works_profile_empathize@3x.webp";

// Profile modal define
import imgWorksProfileDefine_PNG_1x from "./works_profile_define@1x.png";
import imgWorksProfileDefine_PNG_2x from "./works_profile_define@2x.png";
import imgWorksProfileDefine_PNG_3x from "./works_profile_define@3x.png";
import imgWorksProfileDefine_WEBP_1x from "./works_id_define@1x.webp";
import imgWorksProfileDefine_WEBP_2x from "./works_profile_define@2x.webp";
import imgWorksProfileDefine_WEBP_3x from "./works_profile_define@3x.webp";

// Profile modal ideate
import imgWorksProfileIdeate_PNG_1x from "./works_profile_ideate@1x.png";
import imgWorksProfileIdeate_PNG_2x from "./works_profile_ideate@2x.png";
import imgWorksProfileIdeate_PNG_3x from "./works_profile_ideate@3x.png";
import imgWorksProfileIdeate_WEBP_1x from "./works_profile_ideate@1x.webp";
import imgWorksProfileIdeate_WEBP_2x from "./works_profile_ideate@2x.webp";
import imgWorksProfileIdeate_WEBP_3x from "./works_profile_ideate@3x.webp";

// Profile modal prototype
import imgWorksProfilePrototype_PNG_1x from "./works_profile_prototype@1x.png";
import imgWorksProfilePrototype_PNG_2x from "./works_profile_prototype@2x.png";
import imgWorksProfilePrototype_PNG_3x from "./works_profile_prototype@3x.png";
import imgWorksProfilePrototype_WEBP_1x from "./works_profile_prototype@1x.webp";
import imgWorksProfilePrototype_WEBP_2x from "./works_profile_prototype@2x.webp";
import imgWorksProfilePrototype_WEBP_3x from "./works_profile_prototype@3x.webp";

// Profile modal test
import imgWorksProfileTest_PNG_1x from "./works_profile_test@1x.png";
import imgWorksProfileTest_PNG_2x from "./works_profile_test@2x.png";
import imgWorksProfileTest_PNG_3x from "./works_profile_test@3x.png";
import imgWorksProfileTest_WEBP_1x from "./works_profile_test@1x.webp";
import imgWorksProfileTest_WEBP_2x from "./works_profile_test@2x.webp";
import imgWorksProfileTest_WEBP_3x from "./works_profile_test@3x.webp";

// Identity images
import imgWorksIdentity_PNG_1x from "./works_identity@1x.png";
import imgWorksIdentity_PNG_2x from "./works_identity@2x.png";
import imgWorksIdentity_PNG_3x from "./works_identity@3x.png";
import imgWorksIdentity_WebP_1x from "./works_identity@1x.webp";
import imgWorksIdentity_WebP_2x from "./works_identity@2x.webp";
import imgWorksIdentity_WebP_3x from "./works_identity@3x.webp";

// Identity modal hero
import imgWorksIdentityHero_JPG_1x from "./works_id_hero@1x.png";
import imgWorksIdentityHero_JPG_2x from "./works_id_hero@2x.png";
import imgWorksIdentityHero_JPG_3x from "./works_id_hero@3x.png";
import imgWorksIdentityHero_WEBP_1x from "./works_id_hero@1x.webp";
import imgWorksIdentityHero_WEBP_2x from "./works_id_hero@2x.webp";
import imgWorksIdentityHero_WEBP_3x from "./works_id_hero@3x.webp";

// Identity modal empathize
import imgWorksIdentityEmpathize_JPG_1x from "./works_id_empathize@1x.png";
import imgWorksIdentityEmpathize_JPG_2x from "./works_id_empathize@2x.png";
import imgWorksIdentityEmpathize_JPG_3x from "./works_id_empathize@3x.png";
import imgWorksIdentityEmpathize_WEBP_1x from "./works_id_empathize@1x.webp";
import imgWorksIdentityEmpathize_WEBP_2x from "./works_id_empathize@2x.webp";
import imgWorksIdentityEmpathize_WEBP_3x from "./works_id_empathize@3x.webp";

// Identity modal define
import imgWorksIdentityDefine_JPG_1x from "./works_id_define@1x.png";
import imgWorksIdentityDefine_JPG_2x from "./works_id_define@2x.png";
import imgWorksIdentityDefine_JPG_3x from "./works_id_define@3x.png";
import imgWorksIdentityDefine_WEBP_1x from "./works_id_define@1x.webp";
import imgWorksIdentityDefine_WEBP_2x from "./works_id_define@2x.webp";
import imgWorksIdentityDefine_WEBP_3x from "./works_id_define@3x.webp";

// Identity modal ideate
import imgWorksIdentityIdeate_JPG_1x from "./works_id_ideate@1x.png";
import imgWorksIdentityIdeate_JPG_2x from "./works_id_ideate@2x.png";
import imgWorksIdentityIdeate_JPG_3x from "./works_id_ideate@3x.png";
import imgWorksIdentityIdeate_WEBP_1x from "./works_id_ideate@1x.webp";
import imgWorksIdentityIdeate_WEBP_2x from "./works_id_ideate@2x.webp";
import imgWorksIdentityIdeate_WEBP_3x from "./works_id_ideate@3x.webp";

// Identity modal prototype
import imgWorksIdentityPrototype_JPG_1x from "./works_id_prototype@1x.png";
import imgWorksIdentityPrototype_JPG_2x from "./works_id_prototype@2x.png";
import imgWorksIdentityPrototype_JPG_3x from "./works_id_prototype@3x.png";
import imgWorksIdentityPrototype_WEBP_1x from "./works_id_prototype@1x.webp";
import imgWorksIdentityPrototype_WEBP_2x from "./works_id_prototype@2x.webp";
import imgWorksIdentityPrototype_WEBP_3x from "./works_id_prototype@3x.webp";

// Identity modal test
import imgWorksIdentityTest_JPG_1x from "./works_id_test@1x.png";
import imgWorksIdentityTest_JPG_2x from "./works_id_test@2x.png";
import imgWorksIdentityTest_JPG_3x from "./works_id_test@3x.png";
import imgWorksIdentityTest_WEBP_1x from "./works_id_test@1x.webp";
import imgWorksIdentityTest_WEBP_2x from "./works_id_test@2x.webp";
import imgWorksIdentityTest_WEBP_3x from "./works_id_test@3x.webp";

// Forge images
import imgWorksForge_PNG_1x from "./works_forge@1x.png";
import imgWorksForge_PNG_2x from "./works_forge@2x.png";
import imgWorksForge_PNG_3x from "./works_forge@3x.png";
import imgWorksForge_WebP_1x from "./works_forge@1x.webp";
import imgWorksForge_WebP_2x from "./works_forge@2x.webp";
import imgWorksForge_WebP_3x from "./works_forge@3x.webp";

// Forge modal hero
import imgWorksForgeHero_JPG_1x from "./works_forge_hero@1x.png";
import imgWorksForgeHero_JPG_2x from "./works_forge_hero@2x.png";
import imgWorksForgeHero_JPG_3x from "./works_forge_hero@3x.png";
import imgWorksForgeHero_WEBP_1x from "./works_forge_hero@1x.webp";
import imgWorksForgeHero_WEBP_2x from "./works_forge_hero@2x.webp";
import imgWorksForgeHero_WEBP_3x from "./works_forge_hero@3x.webp";

// Forge modal empathize
import imgWorksForgeEmpathize_JPG_1x from "./works_forge_empathize@1x.png";
import imgWorksForgeEmpathize_JPG_2x from "./works_forge_empathize@2x.png";
import imgWorksForgeEmpathize_JPG_3x from "./works_forge_empathize@3x.png";
import imgWorksForgeEmpathize_WEBP_1x from "./works_forge_empathize@1x.webp";
import imgWorksForgeEmpathize_WEBP_2x from "./works_forge_empathize@2x.webp";
import imgWorksForgeEmpathize_WEBP_3x from "./works_forge_empathize@3x.webp";

// Forge modal define
import imgWorksForgeDefine_JPG_1x from "./works_forge_define@1x.png";
import imgWorksForgeDefine_JPG_2x from "./works_forge_define@2x.png";
import imgWorksForgeDefine_JPG_3x from "./works_forge_define@3x.png";
import imgWorksForgeDefine_WEBP_1x from "./works_forge_define@1x.webp";
import imgWorksForgeDefine_WEBP_2x from "./works_forge_define@2x.webp";
import imgWorksForgeDefine_WEBP_3x from "./works_forge_define@3x.webp";

// Forge modal ideate
import imgWorksForgeIdeate_JPG_1x from "./works_forge_ideate@1x.png";
import imgWorksForgeIdeate_JPG_2x from "./works_forge_ideate@2x.png";
import imgWorksForgeIdeate_JPG_3x from "./works_forge_ideate@3x.png";
import imgWorksForgeIdeate_WEBP_1x from "./works_forge_ideate@1x.webp";
import imgWorksForgeIdeate_WEBP_2x from "./works_forge_ideate@2x.webp";
import imgWorksForgeIdeate_WEBP_3x from "./works_forge_ideate@3x.webp";

// Forge modal prototype
import imgWorksForgePrototype_JPG_1x from "./works_forge_prototype@1x.png";
import imgWorksForgePrototype_JPG_2x from "./works_forge_prototype@2x.png";
import imgWorksForgePrototype_JPG_3x from "./works_forge_prototype@3x.png";
import imgWorksForgePrototype_WEBP_1x from "./works_forge_prototype@1x.webp";
import imgWorksForgePrototype_WEBP_2x from "./works_forge_prototype@2x.webp";
import imgWorksForgePrototype_WEBP_3x from "./works_forge_prototype@3x.webp";

// Forge modal test
import imgWorksForgeTest_JPG_1x from "./works_forge_test@1x.png";
import imgWorksForgeTest_JPG_2x from "./works_forge_test@2x.png";
import imgWorksForgeTest_JPG_3x from "./works_forge_test@3x.png";
import imgWorksForgeTest_WEBP_1x from "./works_forge_test@1x.webp";
import imgWorksForgeTest_WEBP_2x from "./works_forge_test@2x.webp";
import imgWorksForgeTest_WEBP_3x from "./works_forge_test@3x.webp";

import thumb_Hairdryer_1x from "./thumb_Hairdryer@1x.png";
import thumb_Hairdryer_2x from "./thumb_Hairdryer@2x.png";
import thumb_Wafer_Handler_1x from "./thumb_Wafer_Handler@1x.png";
import thumb_Wafer_Handler_2x from "./thumb_Wafer_Handler@2x.png";
import thumb_Drawings_1x from "./thumb_Drawings@1x.png";
import thumb_Drawings_2x from "./thumb_Drawings@2x.png";
import thumb_Storyboard_1x from "./thumb_Storyboard@1x.png";
import thumb_Storyboard_2x from "./thumb_Storyboard@2x.png";
import thumb_add_in_1x from "./thumb_add-in@1x.png";
import thumb_add_in_2x from "./thumb_add-in@2x.png";
import thumb_iPrototypes_1x from "./thumb_iPrototypes@1x.png";
import thumb_iPrototypes_2x from "./thumb_iPrototypes@2x.png";
import thumb_Mobile_1x from "./thumb_Mobile@1x.png";
import thumb_Mobile_2x from "./thumb_Mobile@2x.png";
import thumb_thinClient_1x from "./thumb_thinClient@1x.png";
import thumb_thinClient_2x from "./thumb_thinClient@2x.png";
import thumb_profile_1x from "./thumb_profile@1x.png";
import thumb_profile_2x from "./thumb_profile@2x.png";
import thumb_Heatmap_1x from "./thumb_Heatmap@1x.png";
import thumb_Heatmap_2x from "./thumb_Heatmap@2x.png";
import thumb_o2auth_1x from "./thumb_o2auth@1x.png";
import thumb_o2auth_2x from "./thumb_o2auth@2x.png";
import thumb_devPortal_1x from "./thumb_devPortal@1x.png";
import thumb_devPortal_2x from "./thumb_devPortal@2x.png";

import imgWorksHairdryer_1x from "./works_hero_hairdryer@1x.png";
import imgWorksHairdryer_2x from "./works_hero_hairdryer@2x.png";
import imgWorksZdrive_1x from "./works_hero_Zdrive@1x.png";
import imgWorksZdrive_2x from "./works_hero_Zdrive@2x.png";
import imgWorksPatent_1x from "./works_hero_patent@1x.png";
import imgWorksPatent_2x from "./works_hero_patent@2x.png";
import imgWorksStoryboard_1x from "./works_hero_storyboard@1x.png";
import imgWorksStoryboard_2x from "./works_hero_storyboard@2x.png";
import imgWorksAddIns_1x from "./works_hero_add-ins@1x.png";
import imgWorksAddIns_2x from "./works_hero_add-ins@2x.png";
import imgWorksMiniExplorer_1x from "./works_hero_miniExplorer@1x.png";
import imgWorksMiniExplorer_2x from "./works_hero_miniExplorer@2x.png";
import imgWorksTheRepo_1x from "./works_hero_theRepo@1x.png";
import imgWorksTheRepo_2x from "./works_hero_theRepo@2x.png";
import imgWorksThinClient_1x from "./works_hero_thin-client@1x.png";
import imgWorksThinClient_2x from "./works_hero_thin-client@2x.png";
import imgWorksProfile_1x from "./works_hero_profile@1x.png";
import imgWorksProfile_2x from "./works_hero_profile@2x.png";
import imgWorksAnalytics_1x from "./works_hero_analytics_1x.png";
import imgWorksAnalytics_2x from "./works_hero_analytics_2x.png";
import imgWorksSSO_1x from "./works_hero_SSO@1x.png";
import imgWorksSSO_2x from "./works_hero_SSO@2x.png";
import imgWorksLX_1x from "./works_hero_LX@1x.png";
import imgWorksLX_2x from "./works_hero_LX@2x.png";

const slideShowElements = [
  {
    project: "Profile",
    setFirst: true,
    link: "https://profile.autodesk.com",
    hero: {
      desc: "profile.autodesk.com",
      images: [
        imgWorksProfileHero_PNG_1x,
        imgWorksProfileHero_PNG_2x,
        imgWorksProfileHero_PNG_3x,
      ],
      webp: [
        imgWorksProfileHero_WEBP_1x,
        imgWorksProfileHero_WEBP_2x,
        imgWorksProfileHero_WEBP_3x,
      ],
    },
    empathize: {
      desc: "Data observation",
      images: [
        imgWorksProfileEmpathize_PNG_1x,
        imgWorksProfileEmpathize_PNG_2x,
        imgWorksProfileEmpathize_PNG_3x,
      ],
      webp: [
        imgWorksProfileEmpathize_WEBP_1x,
        imgWorksProfileEmpathize_WEBP_2x,
        imgWorksProfileEmpathize_WEBP_3x,
      ],
    },
    define: {
      desc: "State of the problem",
      images: [
        imgWorksProfileDefine_PNG_1x,
        imgWorksProfileDefine_PNG_2x,
        imgWorksProfileDefine_PNG_3x,
      ],
      webp: [
        imgWorksProfileDefine_WEBP_1x,
        imgWorksProfileDefine_WEBP_2x,
        imgWorksProfileDefine_WEBP_3x,
      ],
    },
    ideate: {
      desc: "Affinity clustering",
      images: [
        imgWorksProfileIdeate_PNG_1x,
        imgWorksProfileIdeate_PNG_2x,
        imgWorksProfileIdeate_PNG_3x,
      ],
      webp: [
        imgWorksProfileIdeate_WEBP_1x,
        imgWorksProfileIdeate_WEBP_2x,
        imgWorksProfileIdeate_WEBP_3x,
      ],
    },
    prototype: {
      desc: "Review and evaluation of ideas",
      images: [
        imgWorksProfilePrototype_PNG_1x,
        imgWorksProfilePrototype_PNG_2x,
        imgWorksProfilePrototype_PNG_3x,
      ],
      webp: [
        imgWorksProfilePrototype_WEBP_1x,
        imgWorksProfilePrototype_WEBP_2x,
        imgWorksProfilePrototype_WEBP_3x,
      ],
    },
    test: {
      desc: "User test clickthrough protoypes",
      images: [
        imgWorksProfileTest_PNG_1x,
        imgWorksProfileTest_PNG_2x,
        imgWorksProfileTest_PNG_3x,
      ],
      webp: [
        imgWorksProfileTest_WEBP_1x,
        imgWorksProfileTest_WEBP_2x,
        imgWorksProfileTest_WEBP_3x,
      ],
    },
  },
  {
    project: "Identity",
    setFirst: false,
    link: "https://accounts.autodesk.com",
    hero: {
      desc: "accounts.autodesk.com",
      images: [
        imgWorksIdentityHero_JPG_1x,
        imgWorksIdentityHero_JPG_2x,
        imgWorksIdentityHero_JPG_3x,
      ],
      webp: [
        imgWorksIdentityHero_WEBP_1x,
        imgWorksIdentityHero_WEBP_2x,
        imgWorksIdentityHero_WEBP_3x,
      ],
    },
    empathize: {
      desc: "User observations",
      images: [
        imgWorksIdentityEmpathize_JPG_1x,
        imgWorksIdentityEmpathize_JPG_2x,
        imgWorksIdentityEmpathize_JPG_3x,
      ],
      webp: [
        imgWorksIdentityEmpathize_WEBP_1x,
        imgWorksIdentityEmpathize_WEBP_2x,
        imgWorksIdentityEmpathize_WEBP_3x,
      ],
    },
    define: {
      desc: "How might we...",
      images: [
        imgWorksIdentityDefine_JPG_1x,
        imgWorksIdentityDefine_JPG_2x,
        imgWorksIdentityDefine_JPG_3x,
      ],
      webp: [
        imgWorksIdentityDefine_WEBP_1x,
        imgWorksIdentityDefine_WEBP_2x,
        imgWorksIdentityDefine_WEBP_3x,
      ],
    },
    ideate: {
      desc: "Brainstorming",
      images: [
        imgWorksIdentityIdeate_JPG_1x,
        imgWorksIdentityIdeate_JPG_2x,
        imgWorksIdentityIdeate_JPG_3x,
      ],
      webp: [
        imgWorksIdentityIdeate_WEBP_1x,
        imgWorksIdentityIdeate_WEBP_2x,
        imgWorksIdentityIdeate_WEBP_3x,
      ],
    },
    prototype: {
      desc: "Hi-fi interactive protoypes",
      images: [
        imgWorksIdentityPrototype_JPG_1x,
        imgWorksIdentityPrototype_JPG_2x,
        imgWorksIdentityPrototype_JPG_3x,
      ],
      webp: [
        imgWorksIdentityPrototype_WEBP_1x,
        imgWorksIdentityPrototype_WEBP_2x,
        imgWorksIdentityPrototype_WEBP_3x,
      ],
    },
    test: {
      desc: "A/B experiments",
      images: [
        imgWorksIdentityTest_JPG_1x,
        imgWorksIdentityTest_JPG_2x,
        imgWorksIdentityTest_JPG_3x,
      ],
      webp: [
        imgWorksIdentityTest_WEBP_1x,
        imgWorksIdentityTest_WEBP_2x,
        imgWorksIdentityTest_WEBP_3x,
      ],
    },
  },
  {
    project: "Forge",
    setFirst: true,
    link: "https://forge.autodesk.com",
    hero: {
      desc: "forge.autodesk.com",
      images: [
        imgWorksForgeHero_JPG_1x,
        imgWorksForgeHero_JPG_2x,
        imgWorksForgeHero_JPG_3x,
      ],
      webp: [
        imgWorksForgeHero_WEBP_1x,
        imgWorksForgeHero_WEBP_2x,
        imgWorksForgeHero_WEBP_3x,
      ],
    },
    empathize: {
      desc: "Empathy maps",
      images: [
        imgWorksForgeEmpathize_JPG_1x,
        imgWorksForgeEmpathize_JPG_2x,
        imgWorksForgeEmpathize_JPG_3x,
      ],
      webp: [
        imgWorksForgeEmpathize_WEBP_1x,
        imgWorksForgeEmpathize_WEBP_2x,
        imgWorksForgeEmpathize_WEBP_3x,
      ],
    },
    define: {
      desc: "Journey maps",
      images: [
        imgWorksForgeDefine_JPG_1x,
        imgWorksForgeDefine_JPG_2x,
        imgWorksForgeDefine_JPG_3x,
      ],
      webp: [
        imgWorksForgeDefine_WEBP_1x,
        imgWorksForgeDefine_WEBP_2x,
        imgWorksForgeDefine_WEBP_3x,
      ],
    },
    ideate: {
      desc: "Sketching",
      images: [
        imgWorksForgeIdeate_JPG_1x,
        imgWorksForgeIdeate_JPG_2x,
        imgWorksForgeIdeate_JPG_3x,
      ],
      webp: [
        imgWorksForgeIdeate_WEBP_1x,
        imgWorksForgeIdeate_WEBP_2x,
        imgWorksForgeIdeate_WEBP_3x,
      ],
    },
    prototype: {
      desc: "Clickable lo-fi wireframes",
      images: [
        imgWorksForgePrototype_JPG_1x,
        imgWorksForgePrototype_JPG_2x,
        imgWorksForgePrototype_JPG_3x,
      ],
      webp: [
        imgWorksForgePrototype_WEBP_1x,
        imgWorksForgePrototype_WEBP_2x,
        imgWorksForgePrototype_WEBP_3x,
      ],
    },
    test: {
      desc: "Usability testing",
      images: [
        imgWorksForgeTest_JPG_1x,
        imgWorksForgeTest_JPG_2x,
        imgWorksForgeTest_JPG_3x,
      ],
      webp: [
        imgWorksForgeTest_WEBP_1x,
        imgWorksForgeTest_WEBP_2x,
        imgWorksForgeTest_WEBP_3x,
      ],
    },
  },
];

const portfolioElements = [
  {
    type: "engineering",
    name: "hairdryer",
    src1x: thumb_Hairdryer_1x,
    src2x: thumb_Hairdryer_2x,
    alt: "Project Meili",
    def: "Early career project at Philips",
    desc:
      "<p>A hairdryer with tight engineering requirements that includes a high rated volumetric airflow, wide temperature ratings, and low noise specifications.</p>" +
      "<p>Because of its robust design and sleek profile, it was selected as one of the few celebratory products to represent Philips Singapore marking its 50th year anniversary.</p>" +
      "<p>In this project team of three, I'm mainly responsible for mechanical parts design that are optimized for manufacturing and assembly.</p>",
    hero1x: imgWorksHairdryer_1x,
    hero2x: imgWorksHairdryer_2x,
  },
  {
    type: "engineering",
    name: "semiconductor",
    src1x: thumb_Wafer_Handler_1x,
    src2x: thumb_Wafer_Handler_2x,
    alt: "Electroglas 4090",
    def: "200mm wafer prober",
    desc:
      "<p>Following my career in consumer electronics, I shifted to working in machinery design with a keen focus in semiconductors capital equipment.</p>" +
      "<p>I worked on designing the Electroglas 4090µ+ system, a high quality and effective 200mm wafer probing platform.</p>" +
      "<p>Throughout my stint with Electroglas, I delivered designs that not only contributed to increased product reliability and throughput, but also led initiatives resulting in variants and extensions that fulfil customers' production needs.</p>",
    hero1x: imgWorksZdrive_1x,
    hero2x: imgWorksZdrive_2x,
  },
  {
    type: "engineering",
    name: "drawings",
    src1x: thumb_Drawings_1x,
    src2x: thumb_Drawings_2x,
    alt: "Granted patent",
    def: "Thin wafer chuck design",
    desc:
      "<p>Part of an advanced wafer handling system, the thin wafer chuck is a key component that secures thin wafers in the quality inspection process. What's unique about this design is its ability to handle wafers of very thin specifications; all without any impairment to their fragile thin mounted dies.</p>" +
      "<p>This invention was filed for patent (<a href='https://patents.google.com/patent/US8336188' target='_blank' rel='noreferrer'>US 8336188 B2</a>) and later granted by the United States Patent and Trademark Office.</p>",
    hero1x: imgWorksPatent_1x,
    hero2x: imgWorksPatent_2x,
  },
  {
    type: "design",
    name: "ipad",
    src1x: thumb_Storyboard_1x,
    src2x: thumb_Storyboard_2x,
    alt: "Cloud markups",
    def: "Web based collaboration concept",
    desc:
      "<p>A concept with illustrated storyboards and characters traveling through a journey of collaborating on design data, across multiple locations around the world.</p>" +
      "<p>I undertook the entire project end to end; starting with both user and market research, to the illustration of the narratives, all the way to presenting the materials to the business stakeholders.</p>",
    hero1x: imgWorksStoryboard_1x,
    hero2x: imgWorksStoryboard_2x,
  },
  {
    type: "design",
    name: "addIns",
    src1x: thumb_add_in_1x,
    src2x: thumb_add_in_2x,
    alt: "Vault add-ins",
    def: "Array of middleware applications",
    desc:
      "<p>The Vault add-ins is an array of middleware applications created for each Autodesk’s and competing Computer-Aided-Design (CAD) software.</p>" +
      "<p>Residing within the CAD environment, each individual add-in understands the users’ data model, and connects them to their organization engineering repository for data management, collaboration and analysis.</p>" +
      "<p>I started on the add-ins the day I joined Autodesk and ceased involvement in May 2014. My last role on this team was a design lead, whereby I mentor, distribute and review assignments with design peers based in Munich and Shanghai; while other objectives focused on researching mental models and environmental constraints, designing workflows, and improving designer-developer collaboration.</p>",
    hero1x: imgWorksAddIns_1x,
    hero2x: imgWorksAddIns_2x,
  },
  {
    type: "design",
    name: "miniExplorer",
    src1x: thumb_iPrototypes_1x,
    src2x: thumb_iPrototypes_2x,
    alt: "Mini explorer",
    def: "Experimental WPF application",
    desc:
      "<p>Mini-Explorer serves to deliver a Windows-based application that works as a navigation platform attached to various Computer Aided Design (CAD) software.</p>" +
      "<p>This particular project was created out of empathy for existing users who have been enduring with painful workflows connecting the CAD software to the data storage facility.</p>" +
      "<p>Using a user-centered approach, the application was built from ground up throughout entire development.</p>",
    hero1x: imgWorksMiniExplorer_1x,
    hero2x: imgWorksMiniExplorer_2x,
  },
  {
    type: "design",
    name: "theRepo",
    src1x: thumb_Mobile_1x,
    src2x: thumb_Mobile_2x,
    alt: "The Repo",
    def: "Experimental mobile application",
    desc:
      "<p>The Repository (also known as TheRepo) is an iOS application that allows Autodesk Vault users to view engineering data on the move.</p>" +
      "<p>This application serves mainly shop floor users, providing manufacturing information such as bill of materials (BOM), and associated visualizations for reference and validation.</p>" +
      "<p>This project involved a party of three individuals (including myself), all working on a part-time basis; whereby I was the information architect, visual and interaction designer as well as the product owner.</p>" +
      "<p>My responsibilities involved wire-framing, designing the interface layout, creation of visual assets, and the storyboard scenes in Xcode.</p>",
    hero1x: imgWorksTheRepo_1x,
    hero2x: imgWorksTheRepo_2x,
  },
  {
    type: "design",
    name: "thinClient",
    src1x: thumb_thinClient_1x,
    src2x: thumb_thinClient_2x,
    alt: "Vault thin client",
    def: "Web application hosted behind firewalls",
    desc:
      "<p>A web-based application that allows Vault users view engineering data as well as perform simple modifications off manufacturing shop floor terminals.</p>" +
      "<p>This application was conceived mainly to address deployment pains on Information Technology (IT) administrators given that the existing Windows-based thick client consumes intensive efforts during migration and/or an upgrade.</p>" +
      "<p>Part of a eight member team, I am one of the two user experience personnel for this project. As the only designer, my responsibilities involved creating and maintaining user stories for development; distilling research information; wire-framing and prototyping interactive behaviors.</p>",
    hero1x: imgWorksThinClient_1x,
    hero2x: imgWorksThinClient_2x,
  },
  {
    type: "research",
    name: "profile",
    src1x: thumb_profile_1x,
    src2x: thumb_profile_2x,
    alt: "Profile design",
    def: "Autodesk user profile space",
    desc:
      "<p>Autodesk profile, a key sub-system of Autodesk identity services exists as the main pillar of Autodesk accounts.</p>" +
      "<p>It connects users to their personal information that in turns link up with their Autodesk products and entitlements.</p>" +
      "<p>As the principal designer on the identity services, I laid out the design roadmap for Autodesk profile; one that leads to unification, consistency and deliberation of user information across the eco-system of Autodesk applications.</p>",
    hero1x: imgWorksProfile_1x,
    hero2x: imgWorksProfile_2x,
  },
  {
    type: "research",
    name: "analytics",
    src1x: thumb_Heatmap_1x,
    src2x: thumb_Heatmap_2x,
    alt: "Behavorial analytics",
    def: "Platform for data driven design",
    desc:
      "<p>I started the behavioural insights project in late 2015 in the goal of creating the building blocks for establishing a data-driven design framework. This framework is initially adopted by one of the key projects within Autodesk; and will be gradually deployed across other web applications as it matures.</p>" +
      "<p>This project involves the setup of tag management, along with analytics and test measurement systems for collection of quantative user data. The collected data is followed by series of parsing, interpreting and reporting analysis from collected datasets; which eventually helps stakeholders understand huge amassed complex data better through easy exploration, segmentation and visualization.</p>",
    hero1x: imgWorksAnalytics_1x,
    hero2x: imgWorksAnalytics_2x,
  },
  {
    type: "design",
    name: "identity",
    src1x: thumb_o2auth_1x,
    src2x: thumb_o2auth_2x,
    alt: "Oxygen",
    def: "Autodesk single sign-on service",
    desc:
      "<p>Oxygen is the cloud based authentication and identification platform for Autodesk’s products and services.</p>" +
      "<p>It is a foundational service within the company that provides a customizable layer to suit security, business and legal objectives of concerned applications.</p>" +
      "<p>As the lead designer on this platform, I am responsible for the end-to-end design of complex authentication & authorization features and the overall experience revolving around users' identities.</>" +
      "<p>Apart from the routine design and development work, I regularly study behavioral analytics and trends to drive tactical and strategic plans that are in tandem with users and business needs.</p>",
    hero1x: imgWorksSSO_1x,
    hero2x: imgWorksSSO_2x,
  },
  {
    type: "design",
    name: "devPortal",
    src1x: thumb_devPortal_1x,
    src2x: thumb_devPortal_2x,
    alt: "Forge",
    def: "Cloud based developer platform",
    desc:
      "<p>A web based portal that facilitates internal and external developers with a new approach of building cloud connected apps with Autodesk web APIs.</p>" +
      "<p>This application strives to guide, inform and onboard developers to the Autodesk development ecosystem; as well as functionally provide applications’ access to requested APIs via the OAuth 2.0 protocol.</p>" +
      "<p>As the lead designer on this project, I had significant contributions to this project, namely: setting up the information architecture and navigation structure of the entire site, designing the monetization and billing workflows, as well as providing leadership to other user experience and learning content designers.</p>",
    hero1x: imgWorksLX_1x,
    hero2x: imgWorksLX_2x,
  },
];

// Imagery to support component rendering //

const getMobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"; // iOS detection from: http://stackoverflow.com/a/9039885/177710
  } else {
    return "unknown";
  }
};

class Works extends Component {
  constructor(props) {
    super(props);
    this.state = { Modal: { Header: "", Desc: "", Image1x: "", Image2x: "" } };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (header, definition, description, image1x, image2x) => (e) => {
    this.setState({
      Modal: {
        Header: header,
        Def: definition,
        Desc: description,
        Image1x: image1x,
        Image2x: image2x,
      },
    });
  };

  componentDidMount() {
    if (
      getMobileOperatingSystem === "iOS" ||
      getMobileOperatingSystem === "Android"
    ) {
      document.querySelector("#launch-modal-identity").attr({
        href: "/works/identity",
        target: "_blank",
        rel: "noopener",
      });
      document.querySelector("#launch-modal-identity").removeAttr("uk-toggle");

      document.querySelector("#launch-modal-forge").attr({
        href: "/works/forge",
        target: "_blank",
        rel: "noopener",
      });
      document.querySelector("#launch-modal-forge").removeAttr("uk-toggle");

      console.log("Mobile");
    } else {
      console.log("Desktop");
    }
  }

  render() {
    return (
      <section
        id={"works"}
        className={"uk-margin-xlarge-bottom uk-text-center"}
      >
        <img src={imgWorks} alt={"Works Logo"} />
        <h1
          className={
            "uk-text-center uk-heading-primary uk-text-uppercase uk-margin-remove-bottom uk-margin-small-top"
          }
        >
          Works
        </h1>
        <h5 className={"uk-text-center uk-margin-small-top"}>
          Some of my current &amp; past projects
        </h5>

        {/* Profile */}
        <div
          data-uk-grid
          id={"profile"}
          data-uk-parallax={
            "opacity: .2 ,1; viewport: .5; y: 200, 0; target: #profile;"
          }
          className={"uk-text-left"}
        >
          <div className={"uk-width-expand"}></div>
          <div
            className={
              "uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-width-1-1@s uk-width-5-6@m uk-width-4-5@l uk-margin-xlarge-top"
            }
            data-uk-grid
          >
            <div
              className={
                "uk-flex-last@s uk-card-media-right uk-flex uk-flex-middle uk-padding-small"
              }
            >
              <picture>
                <source
                  srcSet={`${imgWorksProfile_WebP_1x} 320w, ${imgWorksProfile_WebP_2x} 640w, ${imgWorksProfile_WebP_3x} 960w`}
                  type="image/webp"
                />
                <source
                  srcSet={`${imgWorksProfile_PNG_1x} 320w, ${imgWorksProfile_PNG_2x} 640w, ${imgWorksProfile_PNG_3x} 960w`}
                  type="image/png"
                />
                <img
                  data-src={imgWorksForge_PNG_1x}
                  data-srcset={`${imgWorksProfile_PNG_1x} 320w, ${imgWorksProfile_PNG_2x} 640w, ${imgWorksProfile_PNG_3x} 960w`}
                  alt={"Profile"}
                  data-width
                  data-height
                  data-uk-img
                />
              </picture>
            </div>

            <div className={"vert-center"}>
              <div className={"uk-card-body"}>
                <h3 className={"uk-card-title uk-heading-divider"}>Profile</h3>
                <p>
                  A cloud based user profiling service coupled with a serverless
                  application based on micro-frontend architecture, that aims to
                  collect and distill users' data for personalization and
                  improving experiences across Autodesk applications.
                </p>
                <p>
                  <a
                    id={"launch-modal-profile"}
                    className={"btn-readmore uk-button uk-button-default"}
                    href={"#modal-profile"}
                    data-remote={"/works/profile"}
                    data-uk-toggle={"target: #modal-profile"}
                  >
                    Read more
                  </a>
                </p>
                <div
                  id={"modal-profile"}
                  className={"uk-modal-full"}
                  data-uk-modal
                  data-uk-overflow-auto
                >
                  <div className={"uk-width-auto"}>
                    <button
                      className={"uk-modal-close-full uk-close-large"}
                      type={"button"}
                      data-uk-close
                    ></button>
                    <div className={"iframe bg-black"} data-uk-height-viewport>
                      <div
                        className={
                          "uk-grid-collapse uk-child-width-1-2@s uk-flex-middle"
                        }
                        data-uk-grid
                        data-uk-height-viewport
                      >
                        <div className={"uk-padding-large modal-text"}>
                          <h5 className={"uk-text-uppercase uk-text-bold"}>
                            Profile
                          </h5>
                          <p className={"uk-text-small"}>
                            Part of a five-member experience design team, my
                            main responsibilities are to consult on customer
                            journeys and analyze data scenarios required to
                            drive user personalization.
                          </p>
                          <p className={"uk-text-small"}>
                            On a ongoing basis, I continuously mine and analyze
                            usage and contribution data, using it to distil
                            strategies and steer the vision for the service.
                          </p>
                        </div>

                        <SlideShow data={slideShowElements[0]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Identity */}
        <div
          data-uk-grid
          id={"identity"}
          data-uk-parallax={
            "opacity: .2 ,1; viewport: .5; y: 200, 0; target: #identity;"
          }
          className={"uk-text-left"}
        >
          <div
            className={
              "uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-width-1-1@s uk-width-5-6@m uk-width-4-5@l uk-margin-xlarge-top uk-padding-remove-bottom"
            }
            data-uk-grid
          >
            <div
              className={
                "uk-card-media-left uk-flex uk-flex-middle uk-padding-small uk-padding-remove-bottom"
              }
            >
              <picture>
                <source
                  srcSet={`${imgWorksIdentity_WebP_1x} 320w, ${imgWorksIdentity_WebP_2x} 640w, ${imgWorksIdentity_WebP_3x} 960w`}
                  type="image/webp"
                />
                <source
                  srcSet={`${imgWorksIdentity_PNG_1x} 320w, ${imgWorksIdentity_PNG_2x} 640w, ${imgWorksIdentity_PNG_3x} 960w`}
                  type="image/png"
                />
                <img
                  data-src={imgWorksIdentity_PNG_1x}
                  alt={"Identity service"}
                  data-srcset={`${imgWorksIdentity_PNG_1x} 320w, ${imgWorksIdentity_PNG_2x} 640w, ${imgWorksIdentity_PNG_3x} 960w`}
                  data-width
                  data-height
                  data-uk-img
                />
              </picture>
            </div>
            <div className={"vert-center"}>
              <div className={"uk-card-body"}>
                <h3 className={"uk-card-title uk-heading-divider"}>
                  Identity service
                </h3>
                <div>
                  <p>
                    A cloud-based platform service that manages single sign-on
                    sessions, as well as authenticate and authorize users across
                    all Autodesk applications and services.
                  </p>
                  <p>
                    <a
                      id={"launch-modal-identity"}
                      className={"btn-readmore uk-button uk-button-default"}
                      href={"#modal-identity"}
                      data-remote={"/works/identity"}
                      data-uk-toggle={"target: #modal-identity"}
                    >
                      Read more
                    </a>
                  </p>
                  <div
                    id="modal-identity"
                    className="uk-modal-full"
                    data-uk-modal
                    data-uk-overflow-auto
                  >
                    <div className={"uk-width-auto"}>
                      <button
                        className={"uk-modal-close-full uk-close-large"}
                        type={"button"}
                        data-uk-close
                      ></button>
                      <div className="iframe bg-black" data-uk-height-viewport>
                        <div
                          className="uk-grid-collapse uk-child-width-1-2@s uk-flex-middle"
                          data-uk-grid
                          data-uk-height-viewport
                        >
                          <div className="uk-padding-large">
                            <h5 className={"uk-text-uppercase uk-text-bold"}>
                              Identity service
                            </h5>
                            <p className={"uk-text-small"}>
                              As the lead designer on this platform, I am
                              responsible for the end-to-end design of complex
                              authentication &amp; authorization features and
                              the overall experience revolving around users'
                              identities.
                            </p>
                            <p className={"uk-text-small"}>
                              Apart from the routine design and development
                              work, I regularly study behavioral analytics and
                              trends to drive tactical and strategic plans that
                              are in tandem with users and business needs.
                            </p>
                          </div>

                          <SlideShow data={slideShowElements[1]} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"uk-width-expand"}></div>
        </div>

        {/* Forge */}
        <div
          data-uk-grid
          id={"forge"}
          data-uk-parallax={
            "opacity: .2 ,1; viewport: .5; y: 200, 0; target: #forge;"
          }
          className={"uk-text-left"}
        >
          <div className={"uk-width-expand"}></div>
          <div
            className={
              "uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-width-1-1@s uk-width-5-6@m uk-width-4-5@l uk-margin-xlarge-top"
            }
            data-uk-grid
          >
            <div
              className={
                "uk-flex-last@s uk-card-media-right uk-flex uk-flex-middle uk-padding-small"
              }
            >
              <picture>
                <source
                  srcSet={`${imgWorksForge_WebP_1x} 320w, ${imgWorksForge_WebP_2x} 640w, ${imgWorksForge_WebP_3x} 960w`}
                  type="image/webp"
                />
                <source
                  srcSet={`${imgWorksForge_PNG_1x} 320w, ${imgWorksForge_PNG_2x} 640w, ${imgWorksForge_PNG_3x} 960w`}
                  type="image/png"
                />
                <img
                  data-src={imgWorksForge_PNG_1x}
                  data-srcset={`${imgWorksForge_PNG_1x} 320w, ${imgWorksForge_PNG_2x} 640w, ${imgWorksForge_PNG_3x} 960w`}
                  alt={"Forge"}
                  data-width
                  data-height
                  data-uk-img
                />
              </picture>
            </div>

            <div className={"vert-center"}>
              <div className={"uk-card-body"}>
                <h3 className={"uk-card-title uk-heading-divider"}>
                  Developer portal
                </h3>
                <p>
                  An application programming interface (API) portal that allows
                  internal and external developers to learn, build and extend
                  their applications with Autodesk cloud based technologies.
                </p>
                <p>
                  <a
                    id={"launch-modal-forge"}
                    className={"btn-readmore uk-button uk-button-default"}
                    href={"#modal-forge"}
                    data-remote={"/works/forge"}
                    data-uk-toggle={"target: #modal-forge"}
                  >
                    Read more
                  </a>
                </p>
                <div
                  id={"modal-forge"}
                  className={"uk-modal-full"}
                  data-uk-modal
                  data-uk-overflow-auto
                >
                  <div className={"uk-width-auto"}>
                    <button
                      className={"uk-modal-close-full uk-close-large"}
                      type={"button"}
                      data-uk-close
                    ></button>
                    <div className={"iframe bg-black"} data-uk-height-viewport>
                      <div
                        className={
                          "uk-grid-collapse uk-child-width-1-2@s uk-flex-middle"
                        }
                        data-uk-grid
                        data-uk-height-viewport
                      >
                        <div className={"uk-padding-large modal-text"}>
                          <h5 className={"uk-text-uppercase uk-text-bold"}>
                            Developer portal
                          </h5>
                          <p className={"uk-text-small"}>
                            Part of a four-member team, I had significant
                            contributions to this project, namely: setting up
                            the information architecture and navigation
                            structure of the entire site, designing the
                            monetization and billing workflows, as well as
                            providing leadership to other user experience and
                            learning content designers.
                          </p>
                        </div>

                        <SlideShow data={slideShowElements[2]} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Filter*/}

        <div
          data-uk-grid
          id={"filter"}
          data-uk-parallax={"y: 200,0,0; target: #addins;"}
          className={"uk-text-center uk-flex-center"}
        >
          <div
            className={
              "uk-width-1-1 uk-width-5-6@m uk-width-3-4@xl  uk-margin-xlarge-top uk-flex-center"
            }
            data-uk-grid
          >
            <div data-uk-filter={"target: #filter-items"}>
              <div
                id={"filter-control"}
                className={"uk-grid-small uk-flex-middle"}
                data-uk-grid
              >
                <div className={"uk-width-expand"}>
                  <div
                    className={
                      "uk-grid-small uk-grid-divider uk-child-width-auto uk-flex-center"
                    }
                    data-uk-grid
                  >
                    <div>
                      <ul className={"uk-subnav uk-subnav-pill"} data-uk-margin>
                        <li>
                          <span>Projects</span>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <ul
                        className={"uk-subnav uk-subnav-pill uk-flex-center"}
                        data-uk-margin
                      >
                        <li className={"uk-active"} data-uk-filter-control="">
                          <a href="#">All</a>
                        </li>
                        <li uk-filter-control="[data-type='engineering']">
                          <a href="#">Hardware</a>
                        </li>
                        <li uk-filter-control="[data-type='research']">
                          <a href="#">Research</a>
                        </li>
                        <li uk-filter-control="[data-type='design']">
                          <a href="#">Design</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <ul
                id={"filter-items"}
                className={
                  "uk-child-width-1-2 uk-child-width-1-4@s uk-child-width-1-6@xl uk-margin-xlarge-bottom uk-text-center uk-flex-center"
                }
                data-uk-grid
              >
                {portfolioElements.map((data) => {
                  return (
                    <li
                      key={data.name}
                      data-type={data.type}
                      data-name={data.name}
                    >
                      <a
                        href={`#${data.name}`}
                        data-uk-toggle=".portfolio-modal"
                        onClick={this.handleClick(
                          data.alt,
                          data.def,
                          data.desc,
                          data.hero1x,
                          data.hero2x
                        )}
                      >
                        <div
                          className={
                            "uk-card uk-card-primary uk-card-body uk-background-cover"
                          }
                          data-src={data.src1x}
                          data-srcset={`${data.src1x} 265w, ${data.src2x} 490w`}
                          alt={data.alt}
                          data-uk-img
                        >
                          <canvas width="265" height="206"></canvas>
                          <div className={"uk-position-bottom"}>
                            <p
                              className={
                                "uk-margin-remove-bottom uk-text-truncate"
                              }
                            >
                              {data.alt}
                            </p>
                          </div>
                        </div>
                      </a>

                      <div
                        className={"uk-modal-full portfolio-modal"}
                        data-uk-modal
                      >
                        <div
                          className={"uk-width-auto"}
                          data-uk-overflow-auto
                          data-uk-height-viewport
                        >
                          <button
                            className={
                              "uk-position-fixed uk-modal-close-full uk-close-large"
                            }
                            type={"button"}
                            data-uk-close
                          ></button>

                          <div
                            className="iframe uk-background-bottom-right uk-background-norepeat uk-background-contain"
                            data-uk-height-viewport
                            data-src={this.state.Modal.Image1x}
                            data-srcset={`${this.state.Modal.Image1x} 320w, ${this.state.Modal.Image2x} 720w`}
                            data-width
                            data-height
                            data-uk-img
                            data-uk-grid
                          >
                            <div
                              className={
                                "uk-card uk-card-body uk-width-1-1 uk-padding-large iframe-cover uk-flex uk-flex-middle"
                              }
                              data-uk-height-viewport
                            >
                              <dl className="uk-description-list uk-padding-large uk-padding-remove-top uk-padding-remove-bottom">
                                <dt
                                  className={
                                    "uk-padding-small uk-padding-remove-bottom"
                                  }
                                >
                                  {this.state.Modal.Header}
                                </dt>
                                <dd
                                  className={
                                    "works-def uk-text-uppercase uk-text-muted uk-text-small uk-padding-small uk-padding-remove-top uk-margin-small-top"
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.Modal.Def,
                                  }}
                                ></dd>
                                <dd
                                  className={"uk-text-small uk-padding-small"}
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.Modal.Desc,
                                  }}
                                ></dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Works;
