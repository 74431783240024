import React, {Component} from 'react';

class PageHead extends Component {
    render() {
        return (
            <div className="uk-margin-small-top">
                <div className="uk-container">
                    <h6 className="uk-text-uppercase uk-text-small">
                        Skills
                    </h6>
                    <hr/>
                    <h2 className="uk-text-uppercase uk-text-bold uk-light uk-margin-medium-top">
                        {this.props.title}
                    </h2>
                    <div data-uk-grid>
                        <div className="uk-width-1-1@s uk-width-2-3@m uk-text-small"
                             dangerouslySetInnerHTML={{__html: this.props.body}}>
                        </div>
                    </div>
                </div>

                <div className="page-banner uk-margin-large-top uk-margin-large-bottom">
                </div>

            </div>
        )
    }
}

export default PageHead