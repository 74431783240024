import React from "react";

const Banner = (props) => {
  return (
    <div className="uk-position-small uk-position-top-left uk-position-fixed uk-text-muted uk-text-small">
      {props.environment}
    </div>
  );
};

export default Banner;
