import React, {Component} from 'react';
import './develop-banner@1x.jpg';
import './develop-banner@1x.webp';
import Prototyping_JPG_1x from './develop-prototype@1x.jpg';
import Coding_JPG_1x from './develop-coding@1x.jpg';
import Prototyping_WEBP_1x from './develop-prototype@1x.webp';
import Coding_WEBP_1x from './develop-coding@1x.webp';

import './develop.scss';
import {PageFooter} from '../../components/Footer';
import {PageHeader} from "../../components/Header";

import PageHead, {BodyType2} from '../../components/Pages';

const PageHeadTitle = "Develop";

const PageHeadBody1 = "<p>\n" +
    "Having worked on research and analytics over the last decade, I've been helping products\n" +
    "improve user experience and design through data informed decisions.\n" +
    "<br/>\n" +
    "Data forms an important part of my design process, and deriving insights allows me to seek\n" +
    "better\n" +
    "solutions, opportunities and strategies.\n" +
    "</p>";

const PageHeadBody2 = "<p>\n" +
    "Having worked in this trade for more than a decade, I am well versed in both camps of\n" +
    "<a href=\"https://en.wikipedia.org/wiki/User_Research\" target=\"_blank\" rel=\"noopener noreferrer\">user research</a>:\n" +
    "quantitative and qualititative.\n" +
    "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Prototypes";
const BodyText1 = "<p>Axure, Origami, Invision, KeyNote</p>";
const BodyTools1 = [
    {
        "tool": "Keynote",
        "link": "https://www.apple.com/sg/keynote/"
    },
    {
        "tool": "Axure RP",
        "link": "https://www.axure.com/"
    },
    {
        "tool": "Origami",
        "link": "https://origami.design/"
    },
    {
        "tool": "Paper",
        "link": "https://en.wikipedia.org/wiki/Paper_prototyping"
    },
    {
        "tool": "Bootstrap",
        "link": "https://getbootstrap.com/"
    },
    {
        "tool": "UIkit",
        "link": "https://getuikit.com/"
    }
];

const BodyHeading2 = "Coding";
const BodyText2 = "<p>JavaScript, Heroku, Firebase, Python, Php, CSS, HTML, R</p>";
const BodyTools2 = [
    {
        "tool": "HTML / CSS",
        "link": "https://blog.alexdevero.com/html-rapid-prototyping-facts-need-know/"
    },
    {
        "tool": "JavaScript",
        "link": "https://www.w3schools.com/js/"
    },
    {
        "tool": "Sass",
        "link": "https://en.wikipedia.org/wiki/Sass_(stylesheet_language)"
    },
    {
        "tool": "CoffeeScript",
        "link": "https://coffeescript.org/"
    },
    {
        "tool": "PHP",
        "link": "https://php.net/"
    },
    {
        "tool": "Python",
        "link": "https://www.python.org/"
    },
    {
        "tool": "Visual basic",
        "link": "https://en.wikipedia.org/wiki/Visual_Basic/"
    },
    {
        "tool": "Heroku",
        "link": "https://www.heroku.com/"
    },
    {
        "tool": "Firebase",
        "link": "https://firebase.google.com/"
    }
];

class Develop extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-develop" className="skills-pages">
                <PageHeader/>
                <PageHead title={PageHeadTitle} body={PageHeadBody}/>
                <BodyType2 title={PageHeadTitle}
                           subtitle1={BodyHeading1} body1={BodyText1} tools1={BodyTools1}
                           image1JPG={Prototyping_JPG_1x} image1WEBP={Prototyping_WEBP_1x}
                           subtitle2={BodyHeading2} body2={BodyText2} tools2={BodyTools2}
                           image2JPG={Coding_JPG_1x} image2WEBP={Coding_WEBP_1x}/>
                <PageFooter/>
            </div>
        )
    }
}

export default Develop