import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import "./auth0.scss";

export const LoginText = () => {
  const { loginWithRedirect } = useAuth0();
  localStorage.removeItem("name");
  localStorage.removeItem("email");
  localStorage.removeItem("picture");
  localStorage.removeItem("sub");
  return (
    <div>
      <p className="uk-text-small uk-display-inline">
        Personalize your experience.
      </p>
      &nbsp;
      <button
        className="button-link uk-text-small"
        onClick={() => loginWithRedirect()}
      >
        Log In
      </button>
    </div>
  );
};

export const LogoutText = () => {
  const { logout, user } = useAuth0();
  localStorage.setItem("name", user.name);
  localStorage.setItem("email", user.email);
  localStorage.setItem("picture", user.picture);
  localStorage.setItem("sub", user.sub);

  return (
    <div>
      <p className="uk-text-small uk-display-inline">
        You are logged in as {user.name}.
      </p>
      &nbsp;
      <button
        className="button-link uk-text-small"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log out
      </button>
    </div>
  );
};
