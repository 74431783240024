import React, { Component } from "react";
import { LoginText, LogoutText } from "../../auth/auth0";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios/index";
import "./contact.scss";

// Imagery to support component rendering //
import imgContact from "./contact.svg";

const resetForm = () => {
  document.getElementById("form-id-name").setAttribute("set", false);
  document.getElementById("form-id-email").setAttribute("set", false);
  document.getElementById("form-id-msg").setAttribute("set", false);
  document.getElementById("form-id-name").value = "";
  document.getElementById("form-id-email").value = "";
  document.getElementById("form-id-msg").value = "";
  document.getElementById("form-id-name").disabled = false;
  document.getElementById("form-id-email").disabled = false;
  document.getElementById("form-id-msg").disabled = false;
  document.getElementById("form-id-btn").disabled = false;
};

const addRecord = (name, email, msg) => {
  axios
    .post(
      "https://us-central1-alanho-work.cloudfunctions.net/postMail",
      {
        from: email,
        sender: name,
        content: msg,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      console.log(response.data);
      document.getElementById("contact-label").classList.remove("uk-hidden");
      document.getElementById("contact-progress").classList.add("uk-hidden");
      resetForm();

      let bannerElement = document.getElementById("post-banner");

      bannerElement.classList.toggle("uk-hidden");
      bannerElement.classList.toggle("uk-animation-slide-top");

      setTimeout(() => {
        bannerElement.classList.remove("uk-animation-slide-top");
      }, 500);

      setTimeout(() => {
        bannerElement.classList.add(
          "uk-animation-slide-top",
          "uk-animation-reverse"
        );
      }, 5000);

      setTimeout(() => {
        bannerElement.classList.remove(
          "uk-animation-slide-top",
          "uk-animation-reverse"
        );
        bannerElement.classList.toggle("uk-hidden");
      }, 5500);
    })
    .catch(function (error) {
      console.log(error);
    });
};
const manageValidity = (element, isComplete) => {
  if (isComplete === false) {
    element.classList.add("uk-form-danger");
    element.setAttribute("set", false);
  } else {
    element.classList.remove("uk-form-danger");
    element.setAttribute("set", true);
  }
};

const clickSubmit = () => {
  let $name = document.getElementById("form-id-name").value;
  let $email = document.getElementById("form-id-email").value;
  let $msg = document.getElementById("form-id-msg").value;

  if (
    document.getElementById("form-id-name").getAttribute("set") === "true" &&
    document.getElementById("form-id-email").getAttribute("set") === "true" &&
    document.getElementById("form-id-msg").getAttribute("set") === "true"
  ) {
    document.getElementById("form-id-name").disabled = true;
    document.getElementById("form-id-email").disabled = true;
    document.getElementById("form-id-msg").disabled = true;
    document.getElementById("form-id-btn").disabled = true;
    document.getElementById("contact-label").classList.add("uk-hidden");
    document.getElementById("contact-progress").classList.remove("uk-hidden");
    addRecord($name, $email, $msg);
  } else {
    document.getElementById("form-id-btn").classList.add("uk-animation-shake");
    setTimeout(function () {
      document
        .getElementById("form-id-btn")
        .classList.remove("uk-animation-shake");
    }, 500);
  }
};

const changeName = () => {
  let isComplete = false;
  if (document.getElementById("form-id-name").value.length <= 1) {
    isComplete = false;
  } else {
    isComplete = true;
  }
  manageValidity(document.getElementById("form-id-name"), isComplete);
};

const changeEmail = () => {
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isComplete = re.test(document.getElementById("form-id-email").value);
  manageValidity(document.getElementById("form-id-email"), isComplete);
};

const changeMsg = () => {
  let isComplete = false;
  if (document.getElementById("form-id-msg").value.length <= 10) {
    isComplete = false;
  } else {
    isComplete = true;
  }
  manageValidity(document.getElementById("form-id-msg"), isComplete);
};

class Contact extends Component {
  componentDidMount() {
    resetForm();
    document
      .getElementById("form-id-btn")
      .addEventListener("click", clickSubmit, false);
    document
      .getElementById("form-id-name")
      .addEventListener("input", changeName, false);
    document
      .getElementById("form-id-email")
      .addEventListener("input", changeEmail, false);
    document
      .getElementById("form-id-msg")
      .addEventListener("input", changeMsg, false);
  }

  render() {
    const AuthenticationButton = () => {
      const { isAuthenticated } = useAuth0();
      return isAuthenticated ? <LogoutText /> : <LoginText />;
    };

    return (
      <div>
        <div
          id="post-banner"
          className={
            "uk-hidden uk-container uk-position-fixed uk-width-1-1 uk-position-top uk-text-center uk-text-small uk-padding-small uk-position-z-index"
          }
          role="alert"
        >
          Thanks for dropping a note. I'll get back soon.
        </div>

        <section id="contact" className={"uk-container uk-text-center"}>
          <img src={imgContact} alt={"Contact"} />
          <h1
            className={
              "uk-text-center uk-heading-primary uk-text-uppercase uk-margin-remove-bottom uk-margin-small-top"
            }
          >
            Contact
          </h1>
          <h5 className={"uk-text-center uk-margin-small-top"}>
            Stay in touch. Drop me a note.
          </h5>
          <AuthenticationButton />
          <div className={"uk-flex uk-flex-middle"}>
            <div
              className={
                "uk-container uk-margin-xlarge-bottom uk-width-1-1@s uk-width-3-4@m uk-width-1-2@l"
              }
              data-uk-parallax="opacity: 0 ,1; viewport: .25; x: -50%, 0; target: #contact;"
            >
              <div
                id="contact-links"
                className={"uk-margin-medium-top uk-margin-large-bottom"}
              >
                <form className={"uk-form-stacked uk-text-left"}>
                  <div className={"uk-margin-medium-bottom"}>
                    <label className={"uk-form-label"} htmlFor="form-id-name">
                      Your name
                    </label>
                    <div className={"uk-form-controls"}>
                      <input
                        id="form-id-name"
                        className={"uk-input"}
                        type="text"
                        placeholder="John Doe"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="uk-margin-medium-bottom">
                    <label className={"uk-form-label"} htmlFor="form-id-email">
                      Your email
                    </label>
                    <div className={"uk-form-controls"}>
                      <input
                        id="form-id-email"
                        className={"uk-input"}
                        type="email"
                        placeholder="name@company.com"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={"uk-margin-medium-bottom"}>
                    <label className={"uk-form-label"} htmlFor="form-id-msg">
                      Your message
                    </label>
                    <div className={"uk-form-controls"}>
                      <textarea
                        id="form-id-msg"
                        className="uk-textarea"
                        placeholder="My purpose for sending this..."
                        rows="2"
                        disabled={true}
                      ></textarea>
                    </div>
                  </div>
                  <div className={"uk-margin-medium-bottom"}>
                    <button
                      id="form-id-btn"
                      type="button"
                      className={
                        "uk-button uk-button-default uk-align-center uk-width-small"
                      }
                      disabled={true}
                    >
                      <div id="contact-label">Send</div>
                      <div
                        id="contact-progress"
                        className={"uk-hidden"}
                        data-uk-spinner=""
                      ></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
