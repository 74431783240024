// Import Sentry
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { isLocalhost } from "./isLocal";

let sentry_environment = isLocalhost
  ? "local"
  : process.env.REACT_APP_ENVIRONMENT;

const sentryDSN =
  "https://b57c48a3289b4d1b9f1dee1289cb2aa5@o84958.ingest.sentry.io/5776176";

const history = createBrowserHistory();

const sentrySetUser = (props) => {
  return Sentry.setUser(props);
};

const sentryRemoveUser = () => {
  return Sentry.configureScope((scope) => scope.setUser(null));
};

const sentry = (history) => {
  Sentry.init({
    environment: sentry_environment,
    dsn: sentryDSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          "localhost",
          "dev.alanho.work",
          "wwww.alanho.work",
          "alanho.work",
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 1.0,
  });
};

export { sentry, history, sentrySetUser, sentryRemoveUser };
