import React from "react";

const SlideShow = (props) => {

    return (
        <div
            className={"iframe-slideshow uk-background-cover uk-text-center center bg-white " + (props.data.setFirst ? 'uk-flex-first@s' : 'uk-flex-last@s')}>
            <div
                className={"uk-flex uk-flex-column uk-position-relative"}
                data-uk-slideshow={"ratio: 4:3; animation: fade"}>
                <ul className={"uk-slideshow-items"}>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.hero.webp[0]} 320w, ${props.data.hero.webp[1]} 640w, ${props.data.hero.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.hero.images[0]} 320w, ${props.data.hero.images[1]} 640w, ${props.data.hero.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.hero.images[0]}
                                srcSet={`${props.data.hero.images[0]} 320w, ${props.data.hero.images[1]} 640w, ${props.data.hero.images[2]} 960w`}
                                alt={props.data.project}
                            />
                        </picture>

                        <div
                            className={"uk-overlay uk-position-top uk-transition-slide-top"}>
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    <span>{props.data.project}</span>
                                    <a href={props.data.link}
                                       target={"blank"} rel={"nofollow"}
                                       className={"uk-padding-top-remove"}>{props.data.link}</a>
                                </dt>
                            </dl>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.empathize.webp[0]} 320w, ${props.data.empathize.webp[1]} 640w, ${props.data.empathize.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.empathize.images[0]} 320w, ${props.data.empathize.images[1]} 640w, ${props.data.empathize.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.empathize.images[0]}
                                srcSet={`${props.data.empathize.images[0]} 320w, ${props.data.empathize.images[1]} 640w, ${props.data.empathize.images[2]} 960w`}
                                alt={"Empathize"}
                            />
                        </picture>
                        <div
                            className={"uk-overlay uk-overlay-primary uk-position-bottom uk-transition-slide-bottom"}>
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    Empathize
                                </dt>
                                <dd className={"uk-margin-remove uk-text-small"}>
                                    {props.data.empathize.desc}
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.define.webp[0]} 320w, ${props.data.define.webp[1]} 640w, ${props.data.define.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.define.images[0]} 320w, ${props.data.define.images[1]} 640w, ${props.data.define.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.define.images[0]}
                                srcSet={`${props.data.define.images[0]} 320w, ${props.data.define.images[1]} 640w, ${props.data.define.images[2]} 960w`}
                                alt={"Define"}
                            />
                        </picture>
                        <div
                            className={"uk-overlay uk-overlay-primary uk-position-bottom uk-transition-slide-bottom"}>
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    Define
                                </dt>
                                <dd className={"uk-margin-remove uk-text-small"}>
                                    {props.data.define.desc}
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.ideate.webp[0]} 320w, ${props.data.ideate.webp[1]} 640w, ${props.data.ideate.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.ideate.images[0]} 320w, ${props.data.ideate.images[1]} 640w, ${props.data.ideate.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.ideate.images[0]}
                                srcSet={`${props.data.ideate.images[0]} 320w, ${props.data.ideate.images[1]} 640w, ${props.data.ideate.images[2]} 960w`}
                                alt={"Ideate"}
                            />
                        </picture>
                        <div
                            className={"uk-overlay uk-overlay-primary uk-position-bottom uk-transition-slide-bottom"}>
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    Ideate
                                </dt>
                                <dd className={"uk-margin-remove uk-text-small"}>
                                    {props.data.ideate.desc}
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.prototype.webp[0]} 320w, ${props.data.prototype.webp[1]} 640w, ${props.data.prototype.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.prototype.images[0]} 320w, ${props.data.prototype.images[1]} 640w, ${props.data.prototype.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.prototype.images[0]}
                                srcSet={`${props.data.prototype.images[0]} 320w, ${props.data.prototype.images[1]} 640w, ${props.data.prototype.images[2]} 960w`}
                                alt={"Prototype"}
                            />
                        </picture>
                        <div
                            className="uk-overlay uk-overlay-primary uk-position-bottom uk-transition-slide-bottom">
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    Prototype
                                </dt>
                                <dd className={"uk-margin-remove uk-text-small"}>
                                    {props.data.prototype.desc}
                                </dd>
                            </dl>
                        </div>
                    </li>
                    <li>
                        <picture>
                            <source
                                srcSet={`${props.data.test.webp[0]} 320w, ${props.data.test.webp[1]} 640w, ${props.data.test.webp[2]} 960w`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${props.data.test.images[0]} 320w, ${props.data.test.images[1]} 640w, ${props.data.test.images[2]} 960w`}
                                type="image/png"
                            />
                            <img
                                src={props.data.test.images[0]}
                                srcSet={`${props.data.test.images[0]} 320w, ${props.data.test.images[1]} 640w, ${props.data.test.images[2]} 960w`}
                                alt={"Test"}
                            />
                        </picture>
                        <div
                            className="uk-overlay uk-overlay-primary uk-position-bottom uk-transition-slide-bottom">
                            <dl className={"uk-text-left"}>
                                <dt className={"uk-margin-remove"}>
                                    Test
                                </dt>
                                <dd className={"uk-margin-remove uk-text-small"}>
                                    {props.data.test.desc}
                                </dd>
                            </dl>
                        </div>
                    </li>
                </ul>
                <div className={"uk-position-small"}>
                    <ul className="uk-thumbnav">
                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item="0">
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.hero.webp[0]} 320w, ${props.data.hero.webp[1]} 640w, ${props.data.hero.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.hero.images[0]} 320w, ${props.data.hero.images[1]} 640w, ${props.data.hero.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.hero.images[0]}
                                        srcSet={`${props.data.hero.images[0]} 320w, ${props.data.hero.images[1]} 640w, ${props.data.hero.images[2]} 960w`}
                                        alt={props.data.project}
                                    />
                                </picture>
                            </a>
                        </li>

                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item={"1"}>
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.empathize.webp[0]} 320w, ${props.data.empathize.webp[1]} 640w, ${props.data.empathize.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.empathize.images[0]} 320w, ${props.data.empathize.images[1]} 640w, ${props.data.empathize.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.empathize.images[0]}
                                        srcSet={`${props.data.empathize.images[0]} 320w, ${props.data.empathize.images[1]} 640w, ${props.data.empathize.images[2]} 960w`}
                                        alt={"Empathize"}
                                    />
                                </picture>
                            </a>
                        </li>
                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item={"2"}>
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.define.webp[0]} 320w, ${props.data.define.webp[1]} 640w, ${props.data.define.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.define.images[0]} 320w, ${props.data.define.images[1]} 640w, ${props.data.define.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.define.images[0]}
                                        srcSet={`${props.data.define.images[0]} 320w, ${props.data.define.images[1]} 640w, ${props.data.define.images[2]} 960w`}
                                        alt={"Define"}
                                    />
                                </picture>
                            </a>
                        </li>
                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item={"3"}>
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.ideate.webp[0]} 320w, ${props.data.ideate.webp[1]} 640w, ${props.data.ideate.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.ideate.images[0]} 320w, ${props.data.ideate.images[1]} 640w, ${props.data.ideate.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.ideate.images[0]}
                                        srcSet={`${props.data.ideate.images[0]} 320w, ${props.data.ideate.images[1]} 640w, ${props.data.ideate.images[2]} 960w`}
                                        alt={"Ideate"}
                                    />
                                </picture>
                            </a>
                        </li>
                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item={"4"}>
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.prototype.webp[0]} 320w, ${props.data.prototype.webp[1]} 640w, ${props.data.prototype.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.prototype.images[0]} 320w, ${props.data.prototype.images[1]} 640w, ${props.data.prototype.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.prototype.images[0]}
                                        srcSet={`${props.data.prototype.images[0]} 320w, ${props.data.prototype.images[1]} 640w, ${props.data.prototype.images[2]} 960w`}
                                        alt={"Prototype"}
                                    />
                                </picture>
                            </a>
                        </li>
                        <li className={"uk-padding-small"}
                            data-uk-slideshow-item={"5"}>
                            <a href="#">
                                <picture>
                                    <source
                                        srcSet={`${props.data.test.webp[0]} 320w, ${props.data.test.webp[1]} 640w, ${props.data.test.webp[2]} 960w`}
                                        type="image/webp"
                                    />
                                    <source
                                        srcSet={`${props.data.test.images[0]} 320w, ${props.data.test.images[1]} 640w, ${props.data.test.images[2]} 960w`}
                                        type="image/png"
                                    />
                                    <img
                                        src={props.data.test.images[0]}
                                        srcSet={`${props.data.test.images[0]} 320w, ${props.data.test.images[1]} 640w, ${props.data.test.images[2]} 960w`}
                                        alt={"Test"}
                                    />
                                </picture>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SlideShow;