import React, { Component } from "react";
import { Radar } from "react-chartjs-2";
import Loader from "../loader/loader";
import payLoad from "./radar.json";

const chartLoadTime = 1000;

const chartColors = {
  white_100: "rgba(255, 255, 255, 1)",
  white_75: "rgba(255, 255, 255, .75)",
  white_50: "rgba(255, 255, 255, .5)",
  white_25: "rgba(255, 255, 255, .25)",
  white_10: "rgba(255, 255, 255, .10)",
  white_5: "rgba(255, 255, 255, .05)",
};

class ChartRadar extends Component {
  state = {
    loading: true,
    Labels: [],
    Dimensions: [],
    Values: [],
  };

  componentDidMount() {
    let dim_Array = [];
    let dim_Dimensions = [];
    let dim_Value = [];

    for (let [key, value] of Object.entries(payLoad)) {
      if (key === "Areas") {
        dim_Dimensions.push(value);
      } else {
        for (let [array, score] of Object.entries(value)) {
          dim_Value.push(score);
          dim_Array.push(array);
        }
      }
    }

    this.setState(
      {
        Labels: dim_Array,
        Dimensions: dim_Dimensions[0],
        Values: dim_Value,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              loading: false,
            }),
          chartLoadTime + 4000
        ),
      console.info("Radar status: loaded from local")
    );
  }

  render() {
    const hsvToRgb = (r, g, b) => {
      if (arguments.length === 1) {
        g = r.g;
        b = r.b;
        r = r.r;
      }
      let max = Math.max(r, g, b),
        min = Math.min(r, g, b),
        d = max - min,
        h,
        s = max === 0 ? 0 : d / max,
        v = max / 255;

      switch (max) {
        case min:
          h = 0;
          break;
        case r:
          h = g - b + d * (g < b ? 6 : 0);
          h /= 6 * d;
          break;
        case g:
          h = b - r + d * 2;
          h /= 6 * d;
          break;
        case b:
          h = r - g + d * 4;
          h /= 6 * d;
          break;
        default:
          break;
      }

      return [Math.floor(h * 255), Math.floor(s * 255), Math.floor(v * 255)];
    };

    const randomColors = (total) => {
      let i = 360 / (total - 1);
      let r = [];

      for (let x = 0; x < total; x++) {
        r.push(hsvToRgb(i * x, 100, 100).toString());
      }
      return r;
    };

    const radarData = (data) => {
      let r = [];
      for (let x = 0; x < data; x++) {
        r.push({
          label: this.state.Labels[x],
          backgroundColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 0.25)",
          borderColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 0.5)",
          pointStyle: "circle",
          pointBackgroundColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 0.1)",
          pointBorderColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 1)",
          pointHoverBackgroundColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 0.25)",
          pointHoverBorderColor:
            "rgba(" + randomColors(this.state.Labels.length)[x] + ", 1)",
          data: this.state.Values[x],
        });
      }
      return r;
    };

    return (
      <div>
        <Loader loading={this.state.loading} />
        <div
          className="chart-container"
          style={{ visibility: this.state.loading ? "hidden" : "visible" }}
        >
          <Radar
            data={{
              labels: this.state.Dimensions,
              datasets: radarData(this.state.Labels.length),
            }}
            width={this.props.chartWidth}
            height={this.props.chartHeight}
            options={{
              scale: {
                angleLines: {
                  color: chartColors.white_5,
                },
                gridLines: {
                  color: chartColors.white_10,
                },
                ticks: {
                  beginAtZero: true,
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: true,
                labels: {
                  boxWidth: 10,
                  padding: 20,
                },
                position: "bottom",
              },
              tooltips: {
                enabled: true,
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default ChartRadar;
