import React, { Component } from "react";
import moment from "moment/moment";
import imgResume from "./resume.svg";

// Import Analytics Gateway
import { analyticsEventing } from "../../analytics/gateway";

import "./resume.scss";

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = { urlModal: "" };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      urlModal: "https://resume.alanho.work",
    }));
  }

  handleDownload() {
    analyticsEventing("Download", { File: "Resume" });
  }

  componentDidMount() {
    console.log("Loaded resume section");
  }

  render() {
    return (
      <section id="resume" className={"uk-container uk-text-center"}>
        <img src={imgResume} alt={"Resume logo"} />
        <h1
          className={
            "uk-text-center uk-heading-primary uk-text-uppercase uk-margin-remove-bottom uk-margin-small-top"
          }
        >
          Résumé
        </h1>
        <h5 className={"uk-text-center uk-margin-small-top"}>
          A brief summary of my experience
        </h5>
        <div className={"uk-flex uk-flex-middle uk-align-center"}>
          <div
            className={
              "uk-container uk-child-width-1-2@m uk-margin-xlarge-bottom uk-width-auto"
            }
            data-uk-parallax="opacity: 0.5,1; viewport: 0.5;"
          >
            <div
              id="webResume"
              className={"uk-inline uk-tile uk-cover-container uk-first-column"}
              data-aos="fade-up-right"
            >
              <a
                id="cv-online"
                href="#cv-online"
                data-uk-toggle="target: #modal-resume"
                onClick={this.handleClick}
              >
                <div className={"uk-card uk-card-default uk-card-body"}>
                  <article className={"uk-comment"}>
                    <header
                      className={
                        "uk-comment-header uk-grid-medium uk-flex-middle"
                      }
                      data-uk-grid
                    >
                      <div className={"uk-width-auto"}>
                        <span data-uk-icon="icon: laptop; ratio: 2"></span>
                      </div>
                      <div className={"uk-width-expand"}>
                        <h4
                          className={
                            "uk-comment-title uk-margin-remove uk-text-left"
                          }
                        >
                          Web version
                        </h4>
                        <p
                          className={
                            "uk-comment-meta uk-margin-remove-top uk-padding-remove-left uk-text-left"
                          }
                        >
                          Updated&nbsp;
                          <span className={"uk-padding-remove-left"}>
                            {moment(this.props.webUpdated).fromNow()}
                          </span>
                        </p>
                      </div>
                    </header>
                    <div className={"uk-comment-body"}>
                      <p className={"uk-text-meta uk-text-left"}>
                        Online version of my resume.
                      </p>
                    </div>
                  </article>
                </div>
              </a>
            </div>

            <div
              id="pdfResume"
              className={"uk-inline uk-tile uk-cover-container"}
              data-aos="fade-up-left"
            >
              <a
                href="http://bit.ly/brief-cv"
                target="_blank"
                rel="noopener noreferrer"
                id="cv-offline"
                onClick={this.handleDownload}
                download
              >
                <div className={"uk-card uk-card-default uk-card-body"}>
                  <article className={"uk-comment"}>
                    <header
                      className={
                        "uk-comment-header uk-grid-medium uk-flex-middle"
                      }
                      data-uk-grid
                    >
                      <div className={"uk-width-auto"}>
                        <span data-uk-icon="icon: cloud-download; ratio: 2"></span>
                      </div>
                      <div className={"uk-width-expand"}>
                        <h4
                          className={
                            "uk-comment-title uk-margin-remove uk-text-left"
                          }
                        >
                          PDF version
                        </h4>
                        <p
                          className={
                            "uk-comment-meta uk-margin-remove-top uk-text-left"
                          }
                        >
                          Updated&nbsp;
                          <span className={"uk-padding-remove-left"}>
                            {moment(this.props.pdfUpdated).fromNow()}
                          </span>
                        </p>
                      </div>
                    </header>
                    <div className={"uk-comment-body"}>
                      <p className={"uk-text-meta uk-text-left"}>
                        Download for offline reference.
                      </p>
                    </div>
                  </article>
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* Modal Resume */}
        <div id="modal-resume" className={"uk-modal-container"} data-uk-modal>
          <div className={"uk-modal-dialog"}>
            <button
              className={"uk-modal-close-outside"}
              type="button"
              data-uk-close
            ></button>
            <div
              className={"uk-modal-body uk-padding-remove"}
              data-uk-overflow-auto
            >
              <iframe
                title="resume"
                src={this.state.urlModal}
                frameBorder={0}
                data-uk-responsive
              ></iframe>
            </div>
            <div className={"uk-modal-footer uk-text-center"}>
              <p>
                <button
                  className={"uk-button uk-button-default uk-modal-close"}
                  type="button"
                >
                  Close
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
