import React, {Component} from 'react';
import './workshops-banner@1x.jpg';
import './workshops-banner@1x.webp';
import DesignThinking_JPG_1x from './workshops-designthinking@1x.jpg';
import CoDesign_JPG_1x from './workshops-participatory@1x.jpg';
import DesignThinking_WEBP_1x from './workshops-designthinking@1x.webp';
import CoDesign_WEBP_1x from './workshops-participatory@1x.webp';

import './workshops.scss';
import {PageFooter} from '../../components/Footer';
import {PageHeader} from "../../components/Header";

import PageHead, {BodyType1} from '../../components/Pages';

const PageHeadTitle = "Workshops";

const PageHeadBody1 = "<p>\n" +
    "Working in globally distributed projects with users around the world, constant communication is\n" +
    "key to effectively streamline and manage expectations between business stakeholders and the\n" +
    "users.\n" +
    "</p>";

const PageHeadBody2 = "<p>\n" +
    "Relationships are powerful, I build connections by being sensitive to racial, cultural and\n" +
    "economical differences in communication exchanges and actions.\n" +
    "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Design Thinking";
const BodyText1 = "<p>Axure, Origami</p>";
const BodyTools1 = [
    {
        "tool": "LUMA",
        "link": "https://www.luma-institute.com/"
    },
    {
        "tool": "Double diamond",
        "link": "https://www.designcouncil.org.uk/news-opinion/design-process-what-double-diamond/"
    },
];

const BodyHeading2 = "Participatory Design";
const BodyText2 = "<p>Sketch, Figma, Omnigraffle, Balsamiq Mockups</p>";
const BodyTools2 = [
    {
        "tool": "Mural",
        "link": "https://mural.co/"
    },
    {
        "tool": "Brainstorming",
        "link": "https://www.interaction-design.org/literature/topics/brainstorming/"
    },
    {
        "tool": "Brainwriting",
        "link": "https://www.mycoted.com/Brainwriting"
    },
    {
        "tool": "Peer programming",
        "link": "https://medium.com/lightspeed-turtle/why-peer-programming-with-a-designer-is-a-powerful-methodology-171d79cf5875/"
    },
    {
        "tool": "Pluralistic walkthrough",
        "link": "https://en.wikipedia.org/wiki/Pluralistic_walkthrough"
    },
];

class Workshops extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-workshops" className="skills-pages">
                <PageHeader/>
                <PageHead title={PageHeadTitle} body={PageHeadBody}/>
                <BodyType1 title={PageHeadTitle}
                           subtitle1={BodyHeading1} body1={BodyText1} tools1={BodyTools1}
                           image1JPG={DesignThinking_JPG_1x} image1WEBP={DesignThinking_WEBP_1x}
                           subtitle2={BodyHeading2} body2={BodyText2} tools2={BodyTools2}
                           image2JPG={CoDesign_JPG_1x} image2WEBP={CoDesign_WEBP_1x}/>
                <PageFooter/>
            </div>
        )
    }
}

export default Workshops