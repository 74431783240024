import React, {Component} from 'react';
import './research-banner@1x.jpg';
import './research-banner@1x.webp';
import qualitative_JPG_1x from './research-qualitative@1x.jpg';
import quantitative_JPG_1x from './research-quantitative@1x.jpg';
import qualitative_WEBP_1x from './research-qualitative@1x.webp';
import quantitative_WEBP_1x from './research-quantitative@1x.webp';
import './research.scss';
import {PageHeader} from '../../components/Header';
import {PageFooter} from '../../components/Footer';

import PageHead, {BodyType1} from '../../components/Pages';

const PageHeadTitle = "Research";

const PageHeadBody1 = "<p>I've been helping products\n" +
    "improve user experience and workflows through data informed decisions.\n" +
    "Data is an integral part of my design process, fueling the insights required for\n" +
    "clearer objectives and better design solutioning.\n" +
    "</p>";

const PageHeadBody2 = "<p>Having worked on research and analytics for over a decade, I am well versed in both camps of\n" +
    "<a href=\"https://en.wikipedia.org/wiki/User_Research\" target=\"_blank\" rel=\"noopener noreferrer\">user research</a>:\n" +
    "quantitative and qualititative.\n" +
    "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Quantitative";
const BodyText1 = "<p>Before any deployment, I verify my designs for their effectiveness, efficiency and satisfaction scores using an arsenal of tools.\n" +
    "</p>" +
    "<p>On a regular basis, I review analytics data across user\n" +
    "<a href=\"https://en.wikipedia.org/wiki/Touchpoint\" target=\"_blank\" rel=\"noopener noreferrer\">touchpoints</a>" +
    ", seeking out insights on behavioral patterns and conversion trends. From time to time, I also set up and run\n" +
    "<a href=\"https://www.optimizely.com/ab-testing/\" target=\"_blank\" rel=\"noopener noreferrer\">A/B</a> or \n" +
    "<a href=\"https://www.smashingmagazine.com/2011/04/multivariate-testing-101-a-scientific-method-of-optimizing-design/\" target=\"_blank\" rel=\"noopener noreferrer\">multivariate</a>\n" +
    "experiments to prove or refute assumptions.\n" +
    "</p>\n" +
    "<p>I'm a strong proponent of closed loop feedback when it comes to validation the customer experience.\n" +
    "Apart from analyzing usage data, I design &amp; conduct surveys\n" +
    "on prototypes and deployed implementations; setting and measuring against established baselines.\n" +
    "</p>";
const BodyTools1 = [
    {
        "tool": "Split test",
        "link": "https://www.optimizely.com/optimization-glossary/split-testing/"
    },
    {
        "tool": "CSAT",
        "link": "https://www.qualtrics.com/experience-management/customer/what-is-csat/"
    },
    {
        "tool": "Usability test",
        "link": "https://www.experienceux.co.uk/faqs/what-is-usability-testing/"
    },
    {
        "tool": "5 second test",
        "link": "http://www.usefulusability.com/5-second-test/"
    },
    {
        "tool": "Splunk",
        "link": "https://www.splunk.com/"
    },
    {
        "tool": "Optimizely",
        "link": "https://www.optimizely.com/"
    },
    {
        "tool": "Google analytics",
        "link": "https://analytics.google.com/"
    },
    {
        "tool": "R Studio",
        "link": "https://www.rstudio.com/"
    },
    {
        "tool": "Qualtrics",
        "link": "https://www.qualtrics.com/"
    },
];

const BodyHeading2 = "Qualitative";
const BodyText2 = "<p>While quantitative data helps to prove the broad general points of research. I rely on exploratory research \n" +
    "to bring the details and the depth to know my users; develop the necessary\n" +
    "<a href=\"https://www.interaction-design.org/literature/article/design-thinking-getting-started-with-empathy\" target=\"_blank\" rel=\"noopener noreferrer\">empathy</a>" +
    "; getting to understand the goals, motivation, and scenarios leading to these numbers.\n" +
    "</p>";
const BodyTools2 = [
    {
        "tool": "User interview",
        "link": "https://www.interaction-design.org/literature/article/how-to-conduct-user-interviews"
    },
    {
        "tool": "Contextual inquiry",
        "link": "http://www.usabilitybok.org/contextual-inquiry"
    },
    {
        "tool": "Ethnography",
        "link": "https://www.experienceux.co.uk/faqs/what-is-ethnography-research/"
    }
];

class Research extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-research" className="skills-pages">
                <PageHeader/>
                <PageHead title={PageHeadTitle} body={PageHeadBody}/>
                <BodyType1 title={PageHeadTitle}
                           subtitle1={BodyHeading1} body1={BodyText1} tools1={BodyTools1}
                           image1JPG={quantitative_JPG_1x} image1WEBP={quantitative_WEBP_1x}
                           subtitle2={BodyHeading2} body2={BodyText2} tools2={BodyTools2}
                           image2JPG={qualitative_JPG_1x} image2WEBP={qualitative_WEBP_1x}/>
                <PageFooter/>
            </div>
        )
    }
}

export default Research