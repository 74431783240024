import React from "react";
import './bar.scss';

const ProgressBar = (props) => {

    const skills = props.skills;

    return skills.map(skill => (
        <div className="progress-bar" key={skill.name}>
            <p className="uk-text-uppercase uk-text-small">
                {skill.name}
            </p>
            <progress className="uk-progress uk-margin-remove-top uk-margin-remove-bottom" value={skill.value}
                      max="100">
            </progress>
            <p className="uk-text-bold progress-label uk-display-inline uk-position-relative">
                {skill.value}%
            </p>
        </div>
    ));
}

export default ProgressBar;

