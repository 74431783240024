import React, { Component } from "react";
import "./communicate-banner@1x.jpg";
import "./communicate-banner@1x.webp";
import Stakeholders_JPG_1x from "./communicate-stakeholders@1x.jpg";
import Users_JPG_1x from "./communicate-users@1x.jpg";
import Stakeholders_WEBP_1x from "./communicate-stakeholders@1x.jpg";
import Users_WEBP_1x from "./communicate-users@1x.jpg";

import "./communicate.scss";
import { PageFooter } from "../../components/Footer";
import { PageHeader } from "../../components/Header";

import PageHead, { BodyType2 } from "../../components/Pages";

const PageHeadTitle = "Communicate";

const PageHeadBody1 =
  "<p>\n" +
  "Working in globally distributed projects with users around the world, constant communication is\n" +
  "key to effectively streamline and manage expectations between business stakeholders and the\n" +
  "users.\n" +
  "</p>";

const PageHeadBody2 =
  "<p>\n" +
  "I believe relationships are powerful, and I build them by being sensitive to racial, cultural and\n" +
  "economical differences in communication exchanges and actions.\n" +
  "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Stakeholders";
const BodyText1 =
  "<p>On day-to-day operations, I partner with Product Managers, Product Owners, Software\n" +
  "Architects, Scrum Masters and the engineering team.\n" +
  "</p>\n" +
  "<p>Depending on business requirements, there are occasional projects and initiatives that\n" +
  "require me to work with others across Brand, Legal, Marketing, Program Management, Customer\n" +
  "Support as well as other partnering teams.\n" +
  "</p>\n" +
  "<p>Working with my stakeholders, I gather business & legal requirements, identify risks &\n" +
  "constraints, align on approach, priorities and timelines." +
  "</p>";

const BodyTools1 = [
  {
    tool: "Zoom",
    link: "https://zoom.us/",
  },
  {
    tool: "Mural",
    link: "https://mural.co/",
  },
  {
    tool: "Invision",
    link: "https://www.invisionapp.com/",
  },
  {
    tool: "Github",
    link: "https://github.com/",
  },
  {
    tool: "Abstract",
    link: "https://www.abstract.com/",
  },
  {
    tool: "Confluence",
    link: "https://www.atlassian.com/software/confluence/",
  },
  {
    tool: "Jira",
    link: "https://www.atlassian.com/software/jira/",
  },
  {
    tool: "Asana",
    link: "https://asana.com/",
  },
  {
    tool: "Zeplin",
    link: "https://zeplin.io/",
  },
];

const BodyHeading2 = "Users";
const BodyText2 =
  "<p>Over the years, I've worked on multiple products, catering to the goals of varying\n" +
  "user archetypes, including Analysts, Computer Aided Design (CAD) Managers,\n" +
  "Designers, Developers, Engineers, Material Controllers, etc.\n" +
  "</p>\n" +
  "<p>In the course of communicating with the users, I understand their journeys, challenges\n" +
  "and pain-points; bringing forward these conversations to further " +
  "<a href='/research'>research</a> needs.</p>";
const BodyTools2 = [
  {
    tool: "Needs analysis",
    link: "https://en.wikipedia.org/wiki/Needs_analysis/",
  },
  {
    tool: "Thematic analysis",
    link: "https://en.wikipedia.org/wiki/Thematic_analysis",
  },
  {
    tool: "Mental models",
    link: "https://en.wikipedia.org/wiki/Mental_model",
  },
  {
    tool: "Diary studies",
    link: "https://www.nngroup.com/articles/diary-studies/",
  },
];

class Communicate extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id="page-communicate" className="skills-pages">
        <PageHeader />
        <PageHead title={PageHeadTitle} body={PageHeadBody} />
        <BodyType2
          title={PageHeadTitle}
          subtitle1={BodyHeading1}
          body1={BodyText1}
          tools1={BodyTools1}
          image1JPG={Stakeholders_JPG_1x}
          image1WEBP={Stakeholders_WEBP_1x}
          subtitle2={BodyHeading2}
          body2={BodyText2}
          tools2={BodyTools2}
          image2JPG={Users_JPG_1x}
          image2WEBP={Users_WEBP_1x}
        />
        <PageFooter />
      </div>
    );
  }
}

export default Communicate;
