import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import Loader from "../loader/loader";
import payLoad from "./doughnut.json";

const chartLoadTime = 1000;

const shades = (data) => {
  let arrayData = [];
  const maxOpacity = 0.75;
  const deltaOpacity = (maxOpacity - 0) / data.length;

  for (let index = data.length; index > 0; index--) {
    arrayData.push("rgba(255, 255, 255," + deltaOpacity * index + ")");
  }
  return arrayData;
};

const chartColors = {
  white_100: "rgba(255, 255, 255, 1)",
  white_75: "rgba(255, 255, 255, .75)",
  white_50: "rgba(255, 255, 255, .5)",
  white_25: "rgba(255, 255, 255, .25)",
  white_10: "rgba(255, 255, 255, .10)",
  white_5: "rgba(255, 255, 255, .05)",
};

class ChartDoughnut extends Component {
  state = {
    loading: true,
    Dimensions: [],
    Values: [],
  };

  componentDidMount() {
    let dim_Array = [];
    let dim_Value = [];

    for (let [key, value] of Object.entries(payLoad)) {
      dim_Array.push(key);
      dim_Value.push(value);
    }

    this.setState(
      {
        Dimensions: dim_Array,
        Values: dim_Value,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              loading: false,
            }),
          chartLoadTime + 4000
        ),
      console.info("Doughnut status: loaded from local")
    );
  }

  render() {
    return (
      <div>
        <Loader loading={this.state.loading} />
        <div
          className="chart-container"
          style={{ visibility: this.state.loading ? "hidden" : "visible" }}
        >
          <Doughnut
            data={{
              labels: this.state.Dimensions,
              datasets: [
                {
                  label: this.props.chartLabel,
                  data: this.state.Values,
                  backgroundColor: shades(this.state.Values),
                  borderColor: chartColors.white_100,
                  borderWidth: 1,
                  hoverBackgroundColor: chartColors.darkblue_50,
                  hoverBorderColor: chartColors.darkblue_100,
                },
              ],
            }}
            width={this.props.chartWidth}
            height={this.props.chartHeight}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              cutoutPercentage: 50,
              legend: {
                display: true,
                labels: {
                  boxWidth: 10,
                  padding: 20,
                },
                position: "bottom",
              },
              tooltips: {
                enabled: true,
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default ChartDoughnut;
