// Import LogRocket Wrapper
import { logrocketInit, logrocketSetUser } from "./logrocket";

// Import Sentry Wrapper
import { sentry, history, sentrySetUser, sentryRemoveUser } from "./sentry";

export const analyticsGateway = () => {
  // Initialize Sentry
  sentry({ history });

  // Initialize LogRocket
  logrocketInit("hotdurian/alans-personal-home");

  return;
};

export const analyticsEventing = (name, props) => {
  return window.analytics.track(name, props);
};

export const analyticsRemoveUser = () => {
  sentryRemoveUser();
  return window.analytics.reset();
};

const analyticsSetUser = (user_name, user_email, user_sub) => {
  logrocketSetUser(user_name, user_email, user_sub);

  sentrySetUser({
    id: user_sub,
    username: user_name,
    email: user_email,
    ip_address: "{{auto}}",
  });

  window.analytics.identify(user_sub, {
    name: user_name,
    email: user_email,
  });

  return (window.user_id = user_sub);
};

export default analyticsSetUser;
