import React from 'react';
import './spinner.scss';

const Spinners = (props) => {
    return (
        <div className={'spinner-container'} style={{visibility: props.loading ? 'visible' : 'hidden'}}>
            <div className={'uk-light'} data-uk-spinner={`ratio: ${props.ratio}`}>
            </div>
        </div>
    )
}
export default Spinners;