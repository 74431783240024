import React, {Component} from 'react';
import './solutioning-banner@1x.jpg';
import './solutioning-banner@1x.webp';
import Info_Architecture_JPG_1x from './solutioning-infoarch@1x.jpg';
import UserJourney_JPG_1x from './solutioning-visualdesign@1x.jpg';
import Info_Architecture_WEBP_1x from './solutioning-infoarch@1x.webp';
import UserJourney_WEBP_1x from './solutioning-visualdesign@1x.webp';

import './solutioning.scss';
import {PageFooter} from '../../components/Footer';
import {PageHeader} from "../../components/Header";

import PageHead, {BodyType2} from '../../components/Pages';

const PageHeadTitle = "Solutioning";

const PageHeadBody1 = "<p>\n" +
    "Working in globally distributed projects with users around the world, constant communication is\n" +
    "key to effectively streamline and manage expectations between business stakeholders and the\n" +
    "users.\n" +
    "</p>";

const PageHeadBody2 = "<p>\n" +
    "Relationships are powerful, I build connections by being sensitive to racial, cultural and\n" +
    "economical differences in communication exchanges and actions.\n" +
    "</p>";

const PageHeadBody = PageHeadBody1 + PageHeadBody2;

const BodyHeading1 = "Information Architecture";
const BodyText1 = "<p>Axure, Origami</p>";
const BodyTools1 = [
    {
        "tool": "Omnigraffle",
        "link": "https://www.omnigroup.com/omnigraffle/"
    },
    {
        "tool": "Card sorting",
        "link": "https://www.axure.com/"
    },
    {
        "tool": "Tree test",
        "link": "https://www.sketch.com/"
    },
    {
        "tool": "Optimal workshop",
        "link": "https://www.figma.com/"
    }
];

const BodyHeading2 = "Visual design";
const BodyText2 = "<p>JavaScript, Heroku, Firebase, Python, Php, CSS, HTML, Sketch, Figma, Omnigraffle, Balsamiq Mockups</p>";
const BodyTools2 = [
    {
        "tool": "Sketch",
        "link": "https://www.omnigroup.com/omnigraffle/"
    },
    {
        "tool": "Figma",
        "link": "https://www.omnigroup.com/omnigraffle/"
    },
    {
        "tool": "Affinity designer",
        "link": "https://www.axure.com/"
    },
    {
        "tool": "Affinity photo",
        "link": "https://www.sketch.com/"
    }
];

class Solutioning extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="page-solutioning" className="skills-pages">
                <PageHeader/>
                <PageHead title={PageHeadTitle} body={PageHeadBody}/>
                <BodyType2 title={PageHeadTitle}
                           subtitle1={BodyHeading1} body1={BodyText1} tools1={BodyTools1}
                           image1JPG={Info_Architecture_JPG_1x} image1WEBP={Info_Architecture_WEBP_1x}
                           subtitle2={BodyHeading2} body2={BodyText2} tools2={BodyTools2}
                           image2JPG={UserJourney_JPG_1x} image2WEBP={UserJourney_WEBP_1x}/>
                <PageFooter/>
            </div>
        )
    }
}

export default Solutioning