import React, { Component } from "react";
import Avatar_JPG_1x from "./avatar@1x.jpg";
import Avatar_WebP_1x from "./avatar@1x.webp";
import moment from "moment/moment";
import ProgressBar from "../../widgets/progress/bar";

// Import CSS for this component
import "./profile.scss";

const skills = [
  {
    name: "HTML, JavaScript & CSS",
    value: 90,
  },
  {
    name: "Prototyping",
    value: 95,
  },
  {
    name: "Accessibility design",
    value: 90,
  },
  {
    name: "Usability testing",
    value: 85,
  },
  {
    name: "Qualitative research",
    value: 80,
  },
  {
    name: "Quantitative analysis",
    value: 95,
  },
];

class Profile extends Component {
  componentDidMount() {
    console.log("Loaded profile section");
  }

  render() {
    return (
      <section
        id="profile"
        className="uk-position-relative uk-flex uk-flex-center uk-flex-middle"
      >
        <div
          className="uk-width-1-1@s uk-width-3-4@xl uk-child-width-1-3@m"
          data-uk-parallax="opacity: 1, 0.2; viewport: 0.95;  media: @m"
          data-uk-grid
        >
          {/*Left Container*/}
          <div>
            <div
              className="uk-card uk-card-body section-title"
              id="left"
              data-aos="fade-right"
            >
              <div className="uk-margin-large-bottom uk-text-center">
                <div className="uk-text-uppercase profile-title">Bio</div>
              </div>
              <p className="uk-text-small uk-text-left">
                Formerly an engineer, {this.props.firstName} has been designing
                in the software industry for the past&nbsp;
                {moment().diff("2007-10-01", "years")}+ years spanning across
                desktop, mobile and web applications.
              </p>
              <p className="uk-text-small uk-text-left">
                Currently working as a Principal Experience Designer in{" "}
                <a
                  href="https://www.autodesk.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-links"
                >
                  Autodesk
                </a>
                , he specializes in accessible interaction design with an
                interest for user research and software development, building
                pragmatic applications to solve complex business and user
                problems.
              </p>
              <p className="uk-text-small uk-text-left">
                An advocate of continuous prototyping and measurement methods;{" "}
                {this.props.firstName} believes that the experiential factor is
                key to success for any UX design.
              </p>
            </div>
          </div>

          {/*Middle Container*/}
          <div>
            <div
              className="uk-card uk-card-body section-title"
              id="middle"
              data-aos="fade-up"
            >
              <div className="uk-margin-large-bottom uk-text-center">
                <div className="uk-text-uppercase profile-title">Profile</div>
              </div>

              <picture>
                <source
                  className="uk-align-left avatar uk-border-circle uk-position-absolute"
                  srcSet={`${Avatar_WebP_1x} 1x`}
                  type="image/webp"
                />
                <source
                  className="uk-align-left avatar uk-border-circle uk-position-absolute"
                  srcSet={`${Avatar_JPG_1x} 1x`}
                  type="image/jpeg"
                />
                <img
                  src={Avatar_JPG_1x}
                  alt={"Avatar"}
                  className="uk-align-left avatar uk-border-circle uk-position-absolute"
                  srcSet={`${Avatar_JPG_1x} 1x`}
                />
              </picture>

              <div className="uk-align-right" id="brief">
                <h3 className="uk-text-right uk-margin-remove-top uk-margin-small-bottom">
                  {this.props.firstName} {this.props.lastName}{" "}
                </h3>
                <h6 className="uk-text-right uk-margin-remove-top uk-margin-remove-bottom">
                  <span>Data-driven design expert</span>
                </h6>
              </div>
              <blockquote cite="#" className="uk-text-right uk-align-right">
                <p className="uk-align-right">
                  "Design is not art, it is an engineered process that is
                  measureable, repeatable and predictable"
                </p>
              </blockquote>

              <div id="social-icons" className="uk-align-right">
                <ul className="uk-padding-remove">
                  <li>
                    <a
                      href="https://www.linkedin.com/in/alanho"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="linkedin"
                      className="uk-padding-small"
                      title="Connect over LinkedIn"
                      data-uk-tooltip="offset:0"
                      data-uk-icon="icon: linkedin"
                      aria-label="LinkedIn"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.twitter.com/alanhoUX"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="twitter"
                      className="uk-padding-small"
                      title="Follow on Twitter"
                      data-uk-tooltip="offset:0"
                      data-uk-icon="icon: twitter"
                      aria-label="Twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/alanho"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="behance"
                      className="uk-padding-small"
                      title="Follow on Behance"
                      data-uk-tooltip="offset:0"
                      data-uk-icon="icon: behance"
                      aria-label="Behance"
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/*Right Container*/}
          <div>
            <div
              className="uk-card uk-card-body section-title"
              id="right"
              data-aos="fade-left"
            >
              <div className="uk-margin-large-bottom uk-text-center">
                <div className="uk-text-uppercase profile-title">Expertise</div>
              </div>
              <ProgressBar skills={skills} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Profile;
