import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './header.scss';

const navElements = [
    {
        "item": "About me",
        "link": "#profile",
        "desktopID": "nav-profile",
        "mobileID": "side-nav-profile"
    }, {
        "item": "Skills",
        "link": "#skills",
        "desktopID": "nav-skills",
        "mobileID": "side-nav-skills"
    }, {
        "item": "Works",
        "link": "#works",
        "desktopID": "nav-projects",
        "mobileID": "side-nav-projects"
    }, {
        "item": "Résumé",
        "link": "#resume",
        "desktopID": "nav-resume",
        "mobileID": "side-nav-resume"
    }, {
        "item": "Contact",
        "link": "#contact",
        "desktopID": "nav-contact",
        "mobileID": "side-nav-contact"
    }];


class Header extends Component {
    render() {
        return (
            <header className="uk-position-z-index">
                <div
                    data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; bottom: #transparent-sticky-navbar"
                    data-uk-parallax="opacity: 0,1; viewport: .8; x: 30, 0; target: #landing; easing: 2;">

                    <nav className="uk-navbar-container uk-navbar-transparent uk-visible@m" data-uk-navbar>
                        <div className="uk-navbar-right uk-light">
                            <ul className="uk-navbar-nav" data-uk-scrollspy-nav="closest: li; scroll: true;">
                                {navElements.map((data) => {
                                    return (
                                        <li key={data.item} id={data.desktopID}>
                                            <a href={data.link} data-uk-scroll>
                                                {data.item}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </nav>

                    <nav className="uk-navbar-container uk-navbar-transparent uk-hidden@m" data-uk-navbar>
                        <div className="uk-navbar-left uk-light">
                            <ul className="uk-navbar-nav" data-uk-scrollspy-nav="closest: li; scroll: true;">
                                <li>
                                    <a href="" aria-label={"Hamburger icon"} data-uk-toggle="target: #offcanvas">
                                        <span data-uk-icon="icon: menu; ratio: 2"></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>

                </div>

                <div id="offcanvas" data-uk-offcanvas="mode: reveal; overlay: true">
                    <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                        <button className="uk-offcanvas-close" type="button" data-uk-close></button>
                        <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical"
                            data-uk-scrollspy-nav="closest: li; scroll: true;">
                            {navElements.map((data) => {
                                return (
                                    <li key={data.item} id={data.mobile}>
                                        <a href={data.link} className={"uk-text-uppercase"} data-uk-scroll>
                                            {data.item}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </header>
        )
    }
}

const PageHeader = () => {
    return (
        <div className="uk-container uk-padding-small uk-flex uk-flex-left">
            <nav className="uk-navbar-container uk-navbar-transparent page-header" data-uk-navbar>
                <h6 className="uk-text-bold uk-text-uppercase uk-text-small">
                    <Link to="/" className="no-decoration">
                        <span data-uk-icon="chevron-left"></span>
                        <span className="uk-margin-small-left uk-text-middle">Back home</span>
                    </Link>
                </h6>
            </nav>
        </div>
    )
};

export default Header;
export {PageHeader}