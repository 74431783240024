// Import LogRocket
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

// Initialize LogRocket
export const logrocketInit = (prop) => {
  LogRocket.init(prop);
  setupLogRocketReact(LogRocket);
};

export const logrocketSetUser = (user_name, user_email, user_sub) => {
  LogRocket.identify(user_sub, {
    name: user_name,
    email: user_email,
  });
};
